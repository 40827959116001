
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  FormGroup,
  CardFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import React, { useEffect, useState } from 'react';

import { formatDate } from "utils/helpers";
import Select from 'react-select'
import EmiActionTabs from "views/emi/tabs";
import { AddPaymentModel } from "./add";
import { EmiPaymentsTableActions } from 'components/Dropdowns/EmiPaymentsTableActions'
import PageTitle from "views/partials/pageTitle";
import { GetEmiPayments } from "components/Appolo/emi.query";
import RenderPagination from "views/partials/pagination";
import CustomerSearch from "views/partials/customerSearch";
import Filter from "../partials/widgets/filter";


function PaymentsList() {

  const [emiList, setEmiList] = useState([])
  const [runQuery, { loading, data, err }] = GetEmiPayments()
  const [pageLimit, setPageLimit] = useState(0)

  const [page, setPage] = useState(1)

  // define the variables
  const [customerID, setCustomerID] = useState()
  const [filterMonth, setFilterMonth] = useState('')
  const [meta, setMeta] = useState([])

  let variables = {
    page: 1,
    thisMonth: true,
    limit: pageLimit
  }
  const handleLimitSelection = (count) => {
    runQuery({ variables: { ...variables,limit:count,page} })
    setPageLimit(count)
  }
  // On filter clicked
  const handleFilter = () => {
    setPage(1)
    runQuery({
      variables: {
        ...variables,
        "customerID": customerID !== undefined ? customerID : '',
        "thisMonth": filterMonth !== "",
      }
    })
  }

  const handlePaginationClick = (page) => {
    setPage(page)
    runQuery({
      variables: {
        ...variables,
        page: page,
        customerID: customerID !== undefined ? customerID : '',
        thisMonth: filterMonth !== "",
      }
    })
  }

  // On clear clicked
  const handleClear = () => {
    setPage(1)
    runQuery({
      variables: { ...variables }
    })
  }
  useEffect(() => {
    runQuery({
      variables: { ...variables }
    })
  }, []);

  // Get the customers
  useEffect(() => {
    setEmiList(data?.payments?.list)
    setMeta(data?.payments?.meta_data)
  }, [data]);

  const [openModel, setOpenModel] = useState(false)
  const toggleModal = () => {
    setOpenModel(!openModel)
  };

  // onChooseFilterOption
  const onChooseFilterOption = (option) => {
    switch (option) {
      case "all" : 
        variables.thisMonth = false
      break;
      case "this-month" : 
      variables.thisMonth = true
    break;
      default:
        break;
    }

    runQuery({
      variables: { ...variables }
    })
  }
  return (
    <>
      {openModel && <AddPaymentModel state={openModel} toggle={toggleModal} />}

      <PageTitle title="Payments"></PageTitle>
      {/* Page content */}
      <Container className="mt--7" fluid>


        <EmiActionTabs></EmiActionTabs>

        <Row className="">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">EMI Payments</h3>
                  </div>
                  <div className="col text-right">
                  <Filter onChooseFilterOption={onChooseFilterOption}></Filter>

                    <Button
                      className="btn-icon"
                      color="default"
                      type="button"
                      size="md"
                      onClick={() => toggleModal()}
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-fat-add" />
                      </span>
                      <span className="btn-inner--text">Add</span>
                    </Button>
                  </div>
                </Row>

                <Row>
                  <Col md="3">
                    {/* <FormGroup>
                          <Select 
                            options={customerOptions} 
                            clearValue={true} 
                            onChange={(selected) => {
                              setCustomerID(selected.value)
                            }}
                            defaultValue={customerOptions[0]}
                          />
                      </FormGroup> */}
                    <FormGroup>
                      <CustomerSearch onCustomerSelect={(selected) => {
                        setCustomerID(selected.value)
                      }} />
                    </FormGroup>
                  </Col>

                  <Col md="3">
                    <FormGroup>
                      <Button
                        className="btn-icon"
                        color="primary"
                        type="button"
                        size="md"
                        outline
                        onClick={handleFilter}
                      >Filter</Button>
                      <Button
                        className="btn-icon"
                        color="primary"
                        type="button"
                        size="md"
                        outline
                        onClick={handleClear}
                      >Clear</Button>
                    </FormGroup>
                  </Col>
                </Row>


              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Customer ID</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Loan Details</th>
                    <th scope="col">Amount Paid</th>
                    <th scope="col">Date</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    emiList?.map((resource, index) => (
                      <tr key={index}>
                        <td>{(++index) + ((page - 1) * meta.limit)} </td>
                        <td>
                          <Button size="md" color="default" type="button">
                            {resource.customer.customer_id}
                          </Button>
                        </td>
                        <td>
                          {resource.customer.name}
                        </td>
                        <td>
                          {resource.loan.id} - {resource.loan.title}
                        </td>
                        <td>
                          <Button size="md" color="default" outline type="button">
                            Rs {resource.emi_payment.amount_paid}
                          </Button>
                        </td>
                        <td>
                          {formatDate(resource.emi_payment.payment_date)}
                        </td>
                        <td>
                          <EmiPaymentsTableActions loanID={resource.loan.id}></EmiPaymentsTableActions>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
              <CardFooter>
                <div className="col text-right">
                  <RenderPagination 
                    meta={meta} 
                    handlePageClick={handlePaginationClick}
                    onLimitSelected={handleLimitSelection}
                  ></RenderPagination>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>


      </Container>
    </>
  );
};

export default PaymentsList;
