
import {
    Card,
    CardHeader,
    Row,
    Col,
    NavItem,
    Nav,
    NavLink
  } from "reactstrap";
	import classnames from "classnames";

const  EmiActionTabs = () =>{
		const currentUrl = window.location.href;
		const urlParts = currentUrl.split("/");
		const lastUrlPart = urlParts.pop();


		const navItems = [
			{
				isActive : lastUrlPart === "payments",
				url : "/admin/payments",
				title : "Payments"
			},
			{
				isActive : lastUrlPart === "upcoming",
				url : "/admin/emi/upcoming",
				title : "Upcoming"
			},
			{
				isActive : lastUrlPart === "pending",
				url : "/admin/emi/pending",
				title : "Pending"
			},
			{
				isActive : lastUrlPart === "due",
				url : "/admin/emi/due",
				title : "Due List"
			},
			
		]
    return (
        <>
            <Row className="mt-5">
							<Col className="-5" xl="12">
                <Card className="shadow">
                    <CardHeader className="border-0">
                    <Nav
                        className="nav-fill flex-column flex-sm-row"
                        id="tabs-text"
                        pills
                        role="tablist"
                    >

											{
												navItems.map((resource, index) => (
														<NavItem key={index}>
															<NavLink
																className={classnames("mb-sm-3 mb-md-0", {
																	active: resource.isActive
																})}
							
																href={resource.url}
																role="tab"
															>
																	{resource.title}
															</NavLink>
														</NavItem>
												))
											}

											
                    </Nav>
									</CardHeader>
								</Card>
							</Col>
            </Row>
        </>
    )
}

export default EmiActionTabs;
