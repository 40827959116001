
import { Button,Media, Table } from "reactstrap"
import { CloseLoan } from "components/Appolo/loan.query";

// Table
const LoanTable = ({loanList,page, meta}) => {

  // on close loan click
  const closeLoan = (id) => {
    // const {loading, data, err} = CloseLoan({
    //   "loanID" : id,
    // })
  }
  return (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Loan ID</th>
          <th scope="col">Title</th>
          <th scope="col">Customer ID</th>
          <th scope="col">Customer</th>
          <th scope="col">Loan Amount</th>
          <th scope="col">Re-payment Mode</th>
          <th scope="col">Re-payment Amount</th>
          <th scope="col">Paid Amount</th>
          <th scope="col">Balance</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        {
          loanList !== undefined && loanList.map((resource, index) => (
            <tr key={index}>
              <td>{(++index)+ ((page - 1) * meta?.limit)}</td>
              <td>
                <span className="font-weight-bold">
                  <Button color="default" size="md" outline type="button">
                    {resource.loan.id}
                  </Button>
                </span>
              </td>
              <td>
                {resource.loan.title}
              </td>
              <td>
                <Button color="default" size="md" type="button">
                  {resource.customer.customer_id}
                </Button>
              </td>
              <td>

                <Media className="align-items-center">
                  <a
                    className="avatar bg-white mr-3"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >

                    <img
                      alt="..."
                      src={require("../../assets/img/icons/user.png")}
                    />
                  </a>
                  <Media>
                    <span className="mb-0 text-sm">
                      {resource.customer.name}
                    </span>
                  </Media>
                </Media>
              </td>

              <td>
                <span className="font-weight-bold">
                  {resource.loan.loan_amount}
                </span>
              </td>
              <td>
                {resource.loan.emi_mode}
              </td>
              <td>
                {resource.loan.monthly_emi}
              </td>
              <td>
                <Button color="default" size="md" outline type="button">
                  {resource.loan.total_amount_received} - {resource.no_of_emi_paid}/{resource.loan.emi_mode_value}
                </Button>
              </td>

              <td>
                <span className="text-danger font-weight-bold h4">
                  {resource.loan.loan_amount - resource.loan.total_amount_received}
                </span>
                {
                  (resource.loan.loan_amount - resource.loan.total_amount_received) <= 0 && (
                    // resource.loan.loan_amount > 0 &&(
                    <>
                      {/* <Button color="danger" size="sm" type="button" className="m-2" onClick={() => closeLoan(resource.loan.id)}>
                        Close
                      </Button> */}
                    </>
                  )
                }
              </td>
              <td>
                <a className="btn" href={`/admin/loans/detail/${resource.loan.id}`}>View</a>
              </td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  )
}

export default LoanTable