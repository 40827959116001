const { Row } = require("reactstrap")

const LoanDetails = ({loan}) => {
    return (
        <>
            <Row>
                <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center">
                    <div className="col-md-3">
                        <span className="heading">{loan.monthly_emi} / {loan.emi_mode_value}</span>
                        <span className="description">EMI Amount</span>
                    </div>
                    </div>
                </div>
            </Row>
            <Row className="border border-primary">
                <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center ">
                    <div className="col-md-3">
                        <span className="heading">{loan.loan_amount}</span>
                        <span className="description">Loan Amount</span>
                    </div>
                    <div className="col-md-3">
                        <span className="heading">{loan.total_amount_received}</span>
                        <span className="description">Total Emi Paid</span>
                    </div>
                    <div className="col-md-3">
                        <span className="heading">{(loan.loan_amount ? loan.loan_amount : 0) - loan.total_amount_received}</span>
                        <span className="description">balance</span>
                    </div>
                    </div>
                </div>
            </Row>
        </>
    )
}

export default LoanDetails