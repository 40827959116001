import { createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import salesService from "services/sales.service";

// fetchTotalSales
export const fetchTotalSales = createAsyncThunk(
    "sales/total",
    async (IPdata, thunkAPI) => {
      try {
        const data = await salesService.fetchTotalSales(IPdata);
        return data;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        // Check the response status code
        const statusCode = error.response && error.response.status;
        console.log("Response status code:", statusCode);
  
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
);