const { gql, useLazyQuery } = require("@apollo/client");

const PaymentModeGQL = gql`
  query list_payment_modes {
    listPaymentModes {
      id
      short_code
    }
  }
`

const GetPaymentModes = () => {
  const [runQuery, { loading, error, data }] = useLazyQuery(PaymentModeGQL);
  return [runQuery, { loading, error, data }];
}

export {
  GetPaymentModes
}