import { Chart } from "chart.js";
import { useEffect, useState } from "react";
import { chartOptions } from "variables/charts";
import { parseOptions } from "variables/charts";
import { newLoansByMonthChart } from "variables/charts";
import { GetLoansCountByMonth } from "../../components/Appolo/loan.query";

const { Bar } = require("react-chartjs-2")
const { Card, CardHeader, Row, CardBody } = require("reactstrap")

const LoansMonthlyCountChart = () => {
    const [runQuery, { loading, data, err }] = GetLoansCountByMonth()
    const queryVariables = {
        "year": 2024
    }
    let chartLabels = []
    let chartData = []

    // eslint-disable-next-line array-callback-return
    data?.getLoansByMonth?.map((value, index) => {
        chartLabels.push(value.monthName)
        chartData.push(value.count)
    })
    newLoansByMonthChart.data.labels = chartLabels
    newLoansByMonthChart.data.datasets[0].data = chartData


    if (window.Chart) {
        parseOptions(Chart, chartOptions());
    }

    const [year, setYear] = useState(new Date().getFullYear())

    useEffect(() => {
        runQuery({ variables: { ...queryVariables } })
    }, []);

    return (
        <Card className="shadow">
            <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                    <div className="col">
                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                            Performance
                        </h6>
                        <h2 className="mb-0">Loans - New</h2>
                    </div>
                    <div>
                        <i className="btn fas fa-arrow-left text-default mr-3" onClick={() => {
                            let y = year - 1
                            setYear(y)
                            runQuery({ variables: {...queryVariables,"year" : y} })
                        }} />
                        {year} &nbsp;
                        <i className="btn fas fa-arrow-right text-default mr-3" onClick={() => {
                            let y = year + 1
                            setYear(y)
                            runQuery({ variables: {...queryVariables,"year" : y} })
                        }} />
                    </div>
                </Row>
            </CardHeader>
            <CardBody>
                {/* Chart */}
                <div className="chart">
                    {
                        err ? (
                            <p>
                                an error ocured
                            </p>
                        ) : (
                        <>
                            {loading ? (
                                <div>Progressing....</div>
                            ) : (
                                <Bar
                                    data={newLoansByMonthChart.data}
                                    options={newLoansByMonthChart.options}
                                />
                            )
                            }
                        </>

                        )
                    }
                </div>
            </CardBody>
        </Card>
    )
}


export default LoansMonthlyCountChart