import Select from 'react-select'
const { GetPaymentModes } = require("components/Appolo/payment-mode.query")
const { useEffect, useState } = require("react");
const { useDispatch } = require("react-redux");
const { FormGroup } = require("reactstrap");

const PaymentModeSelector = ({onChange, error}) => {
  const dispatch = useDispatch();
  const [listPaymentModes, { loading, data, err }] = GetPaymentModes()
  const [options, setOptions] = useState([])

  useEffect(() => {
    const mappedOptions = data?.listPaymentModes?.map((resource, index) => ({
      value: resource.id,
      label: `${resource.short_code}`,
    }));
    setOptions(mappedOptions);
  }, [data]);

  useEffect(() => {
    listPaymentModes()
  },[dispatch])

  return (
    <FormGroup className={error ? 'has-danger' : ''}>
    <Select options={options} clearValue={true} onChange={onChange} />
  </FormGroup>
  )
}

export default PaymentModeSelector