import {
  Button,
  Input,
  FormGroup,
  Form,
  Modal,
  Card,
  CardBody,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Label,
} from "reactstrap";
import React, { useEffect, useState } from 'react';
import ReactDatetime from "react-datetime";
import Select from 'react-select'

import { useDispatch, useSelector } from "react-redux";
import { setMessage } from 'slices/message';
import { resetApiError } from "slices/api";
import { nameValidator } from "helpers/nameValidator";

import { GetStatusByCategory } from "components/Appolo/status.query";
import AsyncSelect from 'react-select/async';
import { SearchCustomerLoans } from "components/Appolo/loan.query";
import { AddInvestment } from "components/Appolo/investment.query";
import ListAgentFormWidget from "views/partials/widgets/list-agents";

const AddInvestmentModel = (props) => {
  const { state, toggle } = props;
  const [statusoptions, setStatusOption] = useState([])
  const [inputValue, setInputValue] = useState('');
  const [loanID, setLoanID] = useState('');
  const [statusQuery, { loading, error, data }] = GetStatusByCategory()

  const [runQuery, { loading: alLoading, error: alError, data: alData }] = SearchCustomerLoans()
  const [addinvestmentQuery, { loading: aiLoading, error: aiError, data: aiData }] = AddInvestment()


  useEffect(() => {
    statusQuery({
      variables: {
        "category": "INVESTMENT",
      }
    })
  }, [statusQuery])

  useEffect(() => {
    if (data !== undefined && data.getStatusByCategory !== null) {
      let statusOptions = []
      data.getStatusByCategory.map((value, _) => {
        statusOptions = [
          ...statusOptions,
          {
            value: value.id,
            label: value.display_name,
          }
        ]
      })

      setStatusOption(statusOptions)
    }
  }, [data])



  const loadOptions = async (inputValue, callback) => {
    try {
      runQuery({ variables: { customerName: inputValue } })
      const suggestions = alData?.searchCustomerLoans?.map((item) => ({
        value: item.loan.id,
        label: item.customer.name + " [" + item.loan.id + "] " + item.loan.title,
      }));
      callback(suggestions);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      callback([]);
    }
  };

  const handleChange = (selectedOption) => {
    setInputValue(selectedOption ? selectedOption.label : '');
    setLoanID(selectedOption.value)
  };


  const [amount, setAmount] = useState({ value: '', error: '' });
  const [date, setDate] = useState({ value: '', error: '' });
  const [via, setVia] = useState({ value: '', error: '' });
  const [notes, setNotes] = useState({ value: '', error: '' });
  const [status, setStatus] = useState({ value: '', error: '' });
  const [agent, setAgent] = useState({ value: '', error: '', label: '' });
  const [onAgent, setOnAgent] = useState(false);


  const { apiError } = useSelector((state) => state.api);
  const dispatch = useDispatch();
  // check there is an api error
  if (apiError !== undefined && apiError !== "") {
    dispatch(setMessage({
      "type": "error",
      "message": apiError
    }));
    dispatch(resetApiError());

  }
  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    const amountError = nameValidator(amount.value)
    const viaError = nameValidator(via.value)
    const dateError = nameValidator(date.value)
    const statusError = nameValidator(status.value)

    if (amountError || viaError || dateError || statusError) {
      setAmount({ ...amount, error: amountError })
      setVia({ ...via, error: viaError })
      setDate({ ...date, error: dateError })
      setStatus({ ...status, error: statusError })
      return
    }
    const fdate = new Date(date.value);
    const formattedDate = fdate.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric"
    });

    addinvestmentQuery({
      variables: {
        amount: parseInt(amount.value),
        investedOn: formattedDate,
        via: via.value,
        notes: notes.value,
        status: status.value,
        loanID: parseInt(loanID),
        agentID: agent.value,
        onAgent: onAgent
      }
    })
  };

  useEffect(() => {
    if (aiData?.addInvestment?.id !== undefined) {
      dispatch(setMessage({
        "type": "success",
        "message": "You have successfully added new investment data"
      }));
      setTimeout(() => {
        window.location.reload();
      }, 1000)
    }
  }, [aiData, dispatch]);

  useEffect(() => {
    //dispatch(fetchCustomers());
  }, [dispatch,]);


  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={state}
      toggle={toggle}
      size="md"
    >
      <div className="modal-header">
        <h2 className="modal-title" id="exampleModalLabel">
          Add Investment
        </h2>
      </div>
      <div className="modal-body">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="p-0">
            <Form>
              <FormGroup className={amount.error ? 'has-danger' : ''}>
                <Input
                  className="form-control-alternative"
                  id="input-amount"
                  placeholder="Amount"
                  type="text"
                  onChange={(event) => setAmount({ value: event.target.value, error: '' })}
                />
                {
                  amount.error && (
                    <label className="text-danger p-1">{amount.error}</label>
                  )
                }
              </FormGroup>

              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime

                    inputProps={{
                      placeholder: "Date Picker Here"
                    }}
                    timeFormat={false}
                    onChange={(date) => setDate({ value: date, error: '' })}
                    closeOnClickOutside={true}
                    closeOnSelect={true}
                  />
                </InputGroup>
                {
                  date.error && (
                    <label className="text-danger p-1">{date.error}</label>
                  )
                }
              </FormGroup>

              <FormGroup className={via.error ? 'has-danger' : ''}>
                <div className="custom-control custom-control-alternative custom-radio mb-3">
                  <input
                    className="custom-control-input"
                    id="via-gpay"
                    name="input-via"
                    type="radio"
                    onClick={(event) => setVia({ value: "Gpay", error: '' })}
                  />
                  <label className="custom-control-label" htmlFor="via-gpay">
                    Gapy
                  </label>
                  {
                    via.error && (
                      <label className="text-danger p-1">{via.error}</label>
                    )
                  }
                </div>
                <div className="custom-control custom-control-alternative custom-radio mb-3">
                  <input
                    className="custom-control-input"
                    id="via-inhand"
                    name="input-via"
                    type="radio"
                    onClick={(event) => setVia({ value: "Inhand", error: '' })}
                  />
                  <label className="custom-control-label" htmlFor="via-inhand">
                    Inhand
                  </label>
                  {
                    via.error && (
                      <label className="text-danger p-1">{via.error}</label>
                    )
                  }
                </div>


                <Input
                  className="form-control-alternative"
                  id="input-contact"
                  placeholder="Notes"
                  type="textarea"
                  rows="5"
                  onChange={(event) => setNotes({ value: event.target.value, error: '' })}
                />
                {
                  via.error && (
                    <label className="text-danger p-1">{via.error}</label>
                  )
                }
              </FormGroup>

              <FormGroup>
                <Select options={statusoptions} clearValue={false} onChange={(selected) => {
                  setStatus(
                    { value: selected.value, error: '' }
                  )
                }} />
                {
                  status.error && (
                    <label className="text-danger p-1">{status.error}</label>
                  )
                }
              </FormGroup>
              <FormGroup>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={loadOptions}
                  value={{ label: inputValue }}
                  onChange={handleChange}
                  placeholder="Type to search..."
                />
              </FormGroup>

              <ListAgentFormWidget error={agent.error} onChange={(selected) => {
                setAgent({ value: selected.value, error: '' })
              }} >
              </ListAgentFormWidget>

              <FormGroup check>
                <Label check>
                  <Input
                    className="form-control-alternative"
                    type="checkbox"
                    onChange={(e) => setOnAgent(e.target.checked)}
                  />{" "}
                  <strong>On Agent</strong>
                </Label>
              </FormGroup>
              <div className="text-center">
                <Button color="default" type="button" onClick={handleSubmit}>
                  Add
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
}

export {
  AddInvestmentModel
};
