import { ListAgentHoldingSums } from "components/Appolo/agent.query"
import { useEffect, useState } from "react"
import PageTitle from "views/partials/pageTitle"
import { AddAgentTransactionModel } from "./add-agent-transaction"

const { Table, Button, Container, Row, Col, Card, CardHeader, CardBody } = require("reactstrap")


const AgentHoldingsSum = () => {
  const [runQuery, { called, loading, data }] = ListAgentHoldingSums()
  const [page, setPage] = useState(1)
  const [list, setList] = useState([])

  let variables = {
    page: page,
    limit: 0,
    agent_id: "",
  }

  useEffect(() => {
    runQuery({
      variables: {
        ...variables,
      }
    })
  }, [])

  useEffect(() => {
    setList(data?.agentHoldingAmount?.list)
  }, [data]);

  const [openModel, setOpenModel] = useState(false)
  const toggleModal = () => {
    setOpenModel(!openModel)
  };

  return (
    <>
      {openModel && <AddAgentTransactionModel state={openModel} toggle={toggleModal} />}

      <PageTitle title="Agent Holdings List"></PageTitle>
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Agent Payment Holding Sum</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      className="btn-icon"
                      color="default"
                      type="button"
                      size="md"
                      onClick={() => toggleModal()}
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-fat-add" />
                      </span>
                      <span className="btn-inner--text">Add</span>
                    </Button>
                  </div>
                </Row>
                <Row>
                  <Col>
                    <i className="fas fa-arrow-up text-success mr-3 text-sm" /><span className="text-muted"> The amount which the agent holds</span> <br></br>
                    <i className="fas fa-arrow-down text-danger mr-3 text-sm" /> <span className="text-muted"> The amount which needs to pay to the agent </span>
                  </Col>  
                </Row>
              </CardHeader>
              <CardBody>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Agent</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      list?.map((listdata, index) => (
                        <tr key={index}>
                          <td>
                            {(++index) + ((page - 1) * 15)}
                          </td>
                          <td>
                            {listdata.name}
                          </td>
                          <td>
                            {
                              listdata.amount > 0 ? (
                                <i className="fas fa-arrow-up text-success mr-3" />
                              ) : (
                                <i className="fas fa-arrow-down text-danger mr-3" />
                              )
                            }
                            <i className="fa fa-inr" /> {Math.abs(listdata.amount)}
                          </td>
                          <td>
                            <a className="btn" href={`/admin/agent/holding-payments-list/${listdata.id}`}>View</a>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}


export default AgentHoldingsSum