const { gql, useLazyQuery, useMutation } = require("@apollo/client");

const listAgentsGQL = gql`
  query get_agents {
    listAgents{
      id
      name
      email
    }
  }
`


const listAgentHoldingTransactions = gql`
  query agentTransactions($agent_id: String!,$page: Int!,$limit: Int!) {
    listHoldingAmounts(
      filter:{
        agent_id:$agent_id
      },
      pagination: {
        limit :$limit
        page:$page
      }
    ) {
      list {
        id
        agent_id
        payment_id
        category
        real_amount
        amount
        amount_status
        notes
        transaction_date
        customer_name
        agent_name
      }
      meta_data {
        limit
        currentPage
        currentElements
        totalPages
        totalElements
      }
    }
  }
`


const listAgentHoldingSums = gql`
  query AgentHoldingAmount($agent_id: String!,$page: Int!,$limit: Int!) {
    agentHoldingAmount(pagination:{
      page:$page,
      limit:$limit
    }, filter : {
      agent_id: $agent_id
    }) {
      list {
        id
        name
        amount
      }
    }
  }
`


const addAgentTransactionGQL = gql`
  mutation addAgentTransactiion($agent_id: String, $category: String, $amount: Int, $status: AgentTransactionType!, $notes: String!, $date: String){
    addAgentTransaction(input: {
      id:$agent_id
      category:$category
      amount: $amount
      status:$status
      notes:$notes
      date:$date
    })
  }
`
const ListAgents = () => {
  const [runQuery, { loading, error, data }] = useLazyQuery(listAgentsGQL);
  return [runQuery, { loading, error, data }];
}
const ListAgentHoldingTransactions = () => {
  const [runQuery, { loading, error, data }] = useLazyQuery(listAgentHoldingTransactions);
  return [runQuery, { loading, error, data }];
}
const ListAgentHoldingSums = () => {
  const [runQuery, { loading, error, data }] = useLazyQuery(listAgentHoldingSums);
  return [runQuery, { loading, error, data }];
}

const AddAgentTransaction = () => {
  const [mutateFunction, { loading, error, data }] = useMutation(addAgentTransactionGQL);
  return [mutateFunction, { loading, error, data }];
}

export {
  ListAgents,
  ListAgentHoldingTransactions,
  ListAgentHoldingSums,
  AddAgentTransaction
}