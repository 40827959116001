
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import reportService from "services/report.service";

export const fetchMonthlyTurnover = createAsyncThunk(
    "reports/monthly-turnover",
    async (IPData, thunkAPI) => {
      try {
        const data = await reportService.fetchMonthlyTurnover(IPData);
        return data;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        // Check the response status code
        const statusCode = error.response && error.response.status;
        console.log("Response status code:", statusCode);
  
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
);