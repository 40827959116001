import {  createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import customerService from "services/customer.service";

// fetchCustomers
export const fetchCustomers = createAsyncThunk(
  "customers",
  async (Ipdata, thunkAPI) => {
    try {
      const data = await customerService.fetchCustomers(Ipdata);
      return data;
    } catch (error) {
      console.log("error: ", error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      // Check the response status code
      const statusCode = error.response && error.response.status;
      console.log("Response status code:", statusCode);

      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addNewCustomer = createAsyncThunk(
    "customer/add",
    async (ipData, thunkAPI) => {
      try {
        const data = await customerService.addNewCustomer(ipData);
        return data;
      } catch (error) {
        console.log("error: ", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        // Check the response status code
        const statusCode = error.response && error.response.status;
        console.log("Response status code:", statusCode);
  
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  );


  // fetchCustomerDetails
  export const fetchCustomerDetails = createAsyncThunk(
    "customer/details",
    async (ipData, thunkAPI) => {
      try {
        const data = await customerService.fetchCustomerDetails(ipData);
        return data;
      } catch (error) {
        console.log("error: ", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        // Check the response status code
        const statusCode = error.response && error.response.status;
        console.log("Response status code:", statusCode);
  
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  );
