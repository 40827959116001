
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import investmentService from "services/investment.service";


// Add New Investment
export const addInvestment = createAsyncThunk(
    "investment/add",
    async (IPdata, thunkAPI) => {
      try {
        const data = await investmentService.addInvestment(IPdata);
        return data;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        // Check the response status code
        const statusCode = error.response && error.response.status;
        console.log("Response status code:", statusCode);
  
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  );

// Get chart info
export const fetchInvestmentChart = createAsyncThunk(
  "investment/chart",
  async (IPData, thunkAPI) => {
    try {
      const data = await investmentService.fetchinvestmentChart(IPData);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      // Check the response status code
      const statusCode = error.response && error.response.status;
      console.log("Response status code:", statusCode);

      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

// Get chart info
export const fetchEmiChart = createAsyncThunk(
  "emi/chart/month",
  async (_, thunkAPI) => {
    try {
      const data = await investmentService.fetchEmiChart("month");
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      // Check the response status code
      const statusCode = error.response && error.response.status;
      console.log("Response status code:", statusCode);

      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);