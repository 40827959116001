import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/api.service";
import { setMessage } from "./message";
import { 
  addNewCustomer,
  fetchCustomerDetails,
  fetchCustomers
} from "./customer.api";

import { 
  fetchTotalEmi,
  fetchEMIList,
  addEmiPayment, 
  fetchCustomerEmiPayments,
  fetchEmiThisMonth,
  fetchEmiUpcomingMonth,
  fetchEmidueList,
  fetchEmiDueCount,
  fetchEmiPendingList,
} from "./emi.api";

import { 
  addInvestment,
  fetchInvestmentChart,
  fetchEmiChart 
} from "./investment.api";

import { 
  fetchCustomerLoans,
  fetchLoanList,
  addLoan,
  fetchActiveLoans,
} from "./loan.api";

// reports service
import { 
  fetchMonthlyTurnover 
} from "./reports";

import { fetchTotalSales } from "./sales.api";

// userLogin
export const userLogin = createAsyncThunk(
  "login",
  async (data, thunkAPI) => {
    try {
      const resp = await apiService.userLogin(data);
      return resp;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      // Check the response status code
      const statusCode = error.response && error.response.status;
      console.log("Response status code:", statusCode);
      console.log("Response :", error.response);

      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);


// Investment Details
export const fetchInvestmentList = createAsyncThunk(
  "investmentList",
  async (IPdata, thunkAPI) => {
    try {
      const data = await apiService.fetchInvestmentList(IPdata);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      // Check the response status code
      const statusCode = error.response && error.response.status;
      console.log("Response status code:", statusCode);

      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


const initialState = { 
    customersList : [],
    customerDetails : [],
    investmentList : [],
    addedNewInvestment: false,
    investmentChartList : [],
    loanList:[],
    activeLoanList : [],
    customerLoans:[],
    
    emiChartList :[],
    emiList:[],
    emiDueList:[],
    emiPendingList:[],
    emiDueCount:0,
    totalEmi :0,
    totalEmiThisMonth :0,
    totalEmiUpcomingMonth :0,
    isAuthenticated: false,
    reports : {
      monthlyTurnover : {}
    },

    addedNewCustomer: false,
    addedNewEmiPayment: false,
    addedNewLoan: false,

    totalSales : 0,
  }
  
  const apiSlice = createSlice({
      name: "api",
      initialState,
      reducers: {
        resetApiError: (state) => {
          state.apiError = "";
        },
      },
      extraReducers: {
        [userLogin.fulfilled]: (state, action) => {
          state.isAuthenticated = true
          const token = action.payload.data.data
          localStorage.setItem('token',token);
          localStorage.setItem('mode',true);
        },
        [userLogin.rejected]: (state, action) => {
          state.apiError = action.payload.message
        },
        
        [fetchCustomers.fulfilled]: (state, action) => {
            if (action.payload.status === 200 && action.payload.data.data != null){
              state.customersList = action.payload.data.data
            }
        },
        [fetchCustomers.rejected]: (state, action) => {
            console.log("rejected::::",action)
        },

        [fetchInvestmentList.fulfilled]: (state, action) => {
          if (action.payload.status === 200 && action.payload.data != null){
            console.log(action.payload.data)
            state.investmentList = action.payload.data
          }
        },
        [fetchInvestmentList.rejected]: (state, action) => {
            console.log("rejected::::",action)
        },

        [fetchLoanList.fulfilled]: (state, action) => {
            if (action.payload.status === 200 && action.payload.data.data != null){
              state.loanList = action.payload.data.data
            }
        },
        [fetchLoanList.rejected]: (state, action) => {
            console.log("rejected:::: reason",state)
            console.log("rejected:::: reason",action)
        },

        // 
        // Customer
        // 
        [addNewCustomer.fulfilled]: (state, action) => {
            if (action.payload.status === 200){
              state.addedNewCustomer = true
            }
        },
        [addNewCustomer.rejected]: (state, action) => {
            console.log("rejected::::",action)
        },
        
        [fetchCustomerDetails.fulfilled]: (state, action) => {
            if (action.payload.status === 200){
              state.customerDetails = action.payload.data.data
            }
        },
        [fetchCustomerDetails.rejected]: (state, action) => {
            console.log("rejected::::",action)
        },

        [fetchTotalEmi.fulfilled]: (state, action) => {
            if (action.payload.status === 200){
              state.totalEmi = action.payload.data.data
            }
        },
        [fetchTotalEmi.rejected]: (state, action) => {
            console.log("rejected::::",action)
        },

        [fetchEmiThisMonth.fulfilled]: (state, action) => {
            if (action.payload.status === 200){
              state.totalEmiThisMonth = action.payload.data.data
            }
        },
        [fetchEmiThisMonth.rejected]: (state, action) => {
            console.log("rejected::::",action)
        },

        [fetchEmiUpcomingMonth.fulfilled]: (state, action) => {
            if (action.payload.status === 200){
              state.totalEmiUpcomingMonth = action.payload.data.data
            }
        },
        [fetchEmiUpcomingMonth.rejected]: (state, action) => {
            console.log("rejected::::",action)
        },

        [addInvestment.fulfilled]: (state, action) => {
            if (action.payload.status === 200){
              state.addedNewInvestment = true
            }
        },
        [addInvestment.rejected]: (state, action) => {
            console.log("rejected::::",action)
        },

        [fetchInvestmentChart.fulfilled]: (state, action) => {
            if (action.payload.status === 200){
              state.investmentChartList = action.payload.data.data
            }
        },
        [fetchInvestmentChart.rejected]: (state, action) => {
            console.log("rejected::::",action)
        },


        // 
        // EMI 
        // 

        [fetchEmiChart.fulfilled]: (state, action) => {
            if (action.payload.status === 200){
              state.emiChartList = action.payload.data.data
            }
        },
        [fetchEmiChart.rejected]: (state, action) => {
            console.log("rejected::::",action)
        },
        [fetchEMIList.fulfilled]: (state, action) => {
            if (action.payload.status === 200){
              state.emiList = action.payload.data.data
            }
        },
        [fetchEMIList.rejected]: (state, action) => {
            console.log("rejected::::",action)
        },

        // addEmiPayment
        [addEmiPayment.fulfilled]: (state, action) => {
            if (action.payload.status === 200){
              state.addedNewEmiPayment = true
            }
        },
        [addEmiPayment.rejected]: (state, action) => {
            console.log("rejected::::",action)
        },

        // fetchCustomerLoans
        [fetchCustomerLoans.fulfilled]: (state, action) => {
            if (action.payload.status === 200){
              state.customerLoans = action.payload.data.data
            }
        },
        [fetchCustomerLoans.rejected]: (state, action) => {
            console.log("rejected::::",action)
        },

        // emi due list
        [fetchEmidueList.fulfilled]: (state, action) => {
            if (action.payload.status === 200){
              state.emiDueList = action.payload.data.data
            }
        },
        [fetchEmidueList.rejected]: (state, action) => {
            console.log("rejected::::",action)
        },
        // emi due count
        [fetchEmiDueCount.fulfilled]: (state, action) => {
            if (action.payload.status === 200){
              state.emiDueCount = action.payload.data.data
            }
        },
        [fetchEmiDueCount.rejected]: (state, action) => {
            console.log("rejected::::",action)
        },
        [fetchEmiPendingList.fulfilled]: (state, action) => {
            if (action.payload.status === 200){
              state.emiPendingList = action.payload.data.data
            }
        },
        [fetchEmiPendingList.rejected]: (state, action) => {
            console.log("rejected::::",action)
        },

        // fetchCustomerEmiPayments
        // get the emi payments of a customer
        [fetchCustomerEmiPayments.fulfilled]: (state, action) => {
            if (action.payload.status === 200){
              state.emiList = action.payload.data.data
            }
        },
        [fetchCustomerEmiPayments.rejected]: (state, action) => {
            console.log("rejected::::",action)
        },

        // addLoan
        [addLoan.fulfilled]: (state, action) => {
            if (action.payload.status === 200){
              state.addedNewLoan = true
            }
        },
        [addLoan.rejected]: (state, action) => {
            console.log("rejected::::",action)
        },
        // active loans
        [fetchActiveLoans.fulfilled]: (state, action) => {
            if (action.payload.status === 200){
              state.activeLoanList = action.payload.data.data
            }
        },
        [fetchActiveLoans.rejected]: (state, action) => {
            console.log("rejected::::",action)
        },

        // 
        // REPORTS
        // 
        // addLoan
        [fetchMonthlyTurnover.fulfilled]: (state, action) => {
            if (action.payload.status === 200){
              state.reports.monthlyTurnover = action.payload.data
            }
        },
        [fetchMonthlyTurnover.rejected]: (state, action) => {
            console.log("rejected::::",action)
        },

        // 
        // SALES
        // 
        // 
        [fetchTotalSales.fulfilled]: (state, action) => {
            if (action.payload.status === 200){
              state.totalSales = action.payload.data.data
            }
        },
        [fetchTotalSales.rejected]: (state, action) => {
            console.log("rejected::::",action)
        },

      },
  });
  

  export const { resetApiError } = apiSlice.actions;

  const { reducer } = apiSlice;
  export default reducer;