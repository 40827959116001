import { CancelLoan } from "components/Appolo/loan.query";
import { CloseLoan } from "components/Appolo/loan.query";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

const { Button, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } = require("reactstrap")



const LoanActions = ({ details }) => {

  const [closeClicked, setCloseClicked] = useState(false)
  const [closeLoan, { loading, err, data }] = CloseLoan()
  const [cancelClicked, setCancelClicked] = useState(false)
  const [cancelLoan, { loading: cancellLoading, err: cancelErr, data: cancelData }] = CancelLoan()

  const CancellLoanAction = (loanID) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Cancel!",
      cancelButtonText: "No!",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        setCancelClicked(true)
        cancelLoan({
          variables: {
            loanID: loanID
          }
        })
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "action cancelled :)",
          icon: "error"
        });
      }
    });
  }

  const CloseLoanAction = (loanID) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Close!",
      cancelButtonText: "No!",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        setCloseClicked(true)
        closeLoan({
          variables: {
            loanID: loanID
          }
        })
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "action cancelled :)",
          icon: "error"
        });
      }
    });
  }

  useEffect(() => {
    if (closeClicked) {
      if (data?.closeLoan === true) {
        Swal.fire({
          icon: "success",
          title: "Closed",
          text: "The loan has been closed successfully!",
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000)
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops..!",
          text: "An unexpected error occured, we are unable to close the loan!",
        });
      }
    }
  }, [data])

  useEffect(() => {
    if (cancelClicked) {
      if (cancelData?.cancelLoan === true) {
        Swal.fire({
          icon: "success",
          title: "Cancelled",
          text: "The loan has been cancelled successfully!",
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000)
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops..!",
          text: "An unexpected error occured, we are unable to close the loan!",
        });
      }
    }
  }, [cancelData])

  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle
          className="btn-primary text-light"
          role="button"
          size="sm"
          color=""
          onClick={(e) => e.preventDefault()}
        >
            <span className="btn-inner--icon">
              <i className="ni ni-settings" />
            </span>
            Actions
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-arrow" right>

          {
            details?.status_name === "Active"  ?  (
              <>
                <DropdownItem
                  href="/"
                  onClick={(e) => {
                    e.preventDefault()
                    CloseLoanAction(details.loan.id)
                  }}
                >
                  Close loan
                </DropdownItem>
                <DropdownItem
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault()
                    CancellLoanAction(details.loan.id)
                  }}
                >
                  Cancell Loan
                </DropdownItem>
              </>
            ) : (
              <DropdownItem
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault()
                }}
              >
                No action
              </DropdownItem>
            )
          }
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  )
}

export default LoanActions