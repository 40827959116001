import { Modal } from "reactstrap";

const { default: ImageUploader } = require("views/partials/files/upload");

const UploadFileModel = (props) => {
  const { state, toggle, customerid } = props;
  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={state}
      toggle={toggle}
      size="md"
    >
      <div className="modal-header">
        <h2 className="modal-title" id="exampleModalLabel">
          Upload customer Files
        </h2>
      </div>
      <div className="modal-body">
        <ImageUploader id={customerid} referenceInto="customer"></ImageUploader>
      </div>
    </Modal>
  )
}


export default UploadFileModel