
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import loanService from "services/loan.service";

// Loan details
export const fetchLoanList = createAsyncThunk(
    "loanList",
    async (ipdata, thunkAPI) => {
      try {
        const data = await loanService.fetchLoanList(ipdata);
        return data;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        // Check the response status code
        const statusCode = error.response && error.response.status;
        console.log("error:", error);
        console.log("Response status code:", statusCode);
  
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  );

export const fetchCustomerLoans = createAsyncThunk(
    "loans/customer",
    async (ipData, thunkAPI) => {
      try {
        const data = await loanService.fetchCustomerLoans(ipData?.customerId, ipData?.status);
        return data;
      } catch (error) {
        console.log("error: ", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        // Check the response status code
        const statusCode = error.response && error.response.status;
        console.log("Response status code:", statusCode);
  
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
);

// addLoan
export const addLoan = createAsyncThunk(
    "loans/new",
    async (ipData, thunkAPI) => {
      try {
        const data = await loanService.addLoan(ipData);
        return data;
      } catch (error) {
        console.log("error: ", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        // Check the response status code
        const statusCode = error.response && error.response.status;
        console.log("Response status code:", statusCode);
  
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
);

// active loans
export const fetchActiveLoans = createAsyncThunk(
  "loans/active",
  async (ipData, thunkAPI) => {
    try {
      const data = await loanService.fetchActiveLoans(ipData);
      return data;
    } catch (error) {
      console.log("error: ", error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      // Check the response status code
      const statusCode = error.response && error.response.status;
      console.log("Response status code:", statusCode);

      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);