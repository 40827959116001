import { UploadFile } from "components/Appolo/file.query";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, FormGroup, Input, Spinner, } from "reactstrap";
import Swal from "sweetalert2";

const ImageUploader = ({ id, referenceInto }) => {
  const [fileName, setFileName] = useState("file-name")
  const [postImage, setPostImage] = useState({
    myFile: "",
  });
  const [uploader, { data, loading, error }] = UploadFile()
  const [uploading, setUploading] = useState(false)

  const handleSubmit = (e) => {
    setUploading(true)

    e.preventDefault();
    uploader({
      variables: {
        content: postImage.myFile,
        fileName: fileName,
        id: id,
        referenceInto: referenceInto
      }
    })
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setPostImage({ ...postImage, myFile: base64 });
  };

  useEffect(() => {
    if (data === undefined) {
      return
    }

    if (data.uploadFile === true) {
      Swal.fire({
        icon: "success",
        title: "The file uploaded successfully",
        showConfirmButton: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000)
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!, we are unable to upload the file",
      });
    }

    setUploading(false)
  }, [data])
  return (
    <div>
      <Card className="bg-secondary shadow border-0 p-2">
        <CardBody className="p-0">
          <label htmlFor="file-name">File Name</label>

          <FormGroup>
            <Input
              id="file-name"
              className="form-control"
              type="text"
              label="File name"
              name="file-name"
              onChange={(e) => {
                setFileName(e.target.value)
              }}
            />
          </FormGroup>
          <FormGroup>
            <Input
              className="form-control"
              type="file"
              label="Image"
              name="myFile"
              accept=".jpeg, .png, .jpg, .pdf"
              onChange={(e) => handleFileUpload(e)}
            />
          </FormGroup>

          {
            uploading ? (
              <Spinner color="primary">
                uploading.....
              </Spinner>
            ) : (
              <Button color="primary" onClick={handleSubmit}>
                Upload
              </Button>
            )
          }


        </CardBody>
      </Card>
    </div>
  );
}

export default ImageUploader