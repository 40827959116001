import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Button,
  Media,
  FormGroup,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import Select from 'react-select'

import { formatDate } from "utils/helpers";

import { fetchLoanList } from "slices/loan.api";
import { fetchCustomers } from "slices/customer.api";
import { fetchEmidueList } from "slices/emi.api";
import EmiActionTabs from "views/emi/tabs";
import PageTitle from "views/partials/pageTitle";
import EMITable from "./table";

function EmiDueList() {
  const dispatch = useDispatch();
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerID, setCustomerID] = useState()

  const { customersList, loanList, emiDueList } = useSelector((state) => state.api);

  // On filter clicked
  const handleFilter = () => {
    dispatch(
      fetchLoanList({
        customer_id: customerID,
      })
    )
  }

  // On clear clicked
  const handleClear = () => {
    dispatch(
      fetchLoanList()
    )
  }

  useEffect(() => {
    dispatch(fetchEmidueList());
    dispatch(fetchLoanList());
    dispatch(fetchCustomers());
  }, [dispatch]);

  // load the customers
  useEffect(() => {
    const mappedOptions = customersList.map((resource, index) => ({
      value: resource.customer_id,
      label: `${resource.customer_id} - ${resource.name}`,
    }));
    setCustomerOptions(mappedOptions);
  }, [customersList]);


  return (
    <>
      <PageTitle title="EMI due list" />

      {/* Page content */}
      <Container className="mt--7" fluid>

        <EmiActionTabs />

        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Emi Due List</h3>
                  </div>
                </Row>

                <Row className="mt-3">
                  <Col md="3">
                    <FormGroup>
                      <Select options={customerOptions} clearValue={true} onChange={(selected) => {
                        setCustomerID(selected.value)
                      }} />
                    </FormGroup>
                  </Col>

                  <Col md="3">
                    <FormGroup>
                      <Button
                        className="btn-icon"
                        color="primary"
                        type="button"
                        size="md"
                        outline
                        onClick={handleFilter}
                      >Filter</Button>
                      <Button
                        className="btn-icon"
                        color="primary"
                        type="button"
                        size="md"
                        outline
                        onClick={handleClear}
                      >Clear</Button>
                    </FormGroup>
                  </Col>
                </Row>

              </CardHeader>
              <EMITable
                headers={[
                  "#", "Loan ID", "Title", "Customer ID", "Customer", "Loan Amount", "Re-payment Mode", "Re-payment Amount",
                  "EMI Due From", "Actions"
                ]}
                data={emiDueList}
              ></EMITable>
            </Card>
          </Col>
        </Row>


      </Container>
    </>
  );
};

export default EmiDueList;
