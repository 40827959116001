import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
  } from "reactstrap";

  const EmiPaymentsTableActions = ({loanID}) => {
    return (
        <UncontrolledDropdown>
            <DropdownToggle
                className="btn-icon-only text-light"
                href="#pablo"
                role="button"
                size="sm"
                color=""
                onClick={(e) => e.preventDefault()}
            >
                <i className="fas fa-ellipsis-v" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem
                href={`/admin/loans/detail/${loanID}`}
                >
                    View Loan Details 
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

export {
    EmiPaymentsTableActions
};
