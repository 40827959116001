import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Button,
  Progress,
} from "reactstrap";

import Header from "components/Headers/HeaderLatest.js";
import SalesChartItem from "./sales/chart";
import EmiPaymentsChart from "./payments/chart";
import InvestmentChart from "./investment/chart";
import RecentActivities from "./partials/dashboard/recent-activities";
import LoansMonthlyCountChart from "./loan/monthly-chart";

const Index = (props) => {

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
            <EmiPaymentsChart></EmiPaymentsChart>
          </Col>
          <Col xl="4">
            <InvestmentChart/>
          </Col>
        </Row>

        {/* sales graph */}
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="8">
            <SalesChartItem></SalesChartItem>
          </Col>
          <Col xl="4">
            {/* <Relationships></Relationships> */}
            <LoansMonthlyCountChart></LoansMonthlyCountChart>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <RecentActivities></RecentActivities>
          </Col>
         
        </Row>
      </Container>
    </>
  );
};

export default Index;
