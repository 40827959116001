

import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    Table,
    CardTitle,
  } from "reactstrap";

import { useDispatch , useSelector} from "react-redux";
import React, { useEffect,useState } from 'react';
import { IsEmptyObject } from "utils/helpers";
import { fetchMonthlyTurnover } from "slices/reports";
import PageTitle from "views/partials/pageTitle";
import DataList from "./data-list";
const ProfitCalculator = () => {

    const dispatch = useDispatch();
    const { reports } = useSelector((state) => state.api);
    const [totalProfit, setTotalProfit] = useState(0)
    const [totalInvestment, setTotalInvestment] = useState(0)

    useEffect(() => {
       dispatch(fetchMonthlyTurnover({
        Latest: true,
       }))
    },[dispatch])

    useEffect(() => {
      if (reports !== undefined) {
        let totalProfit = 0
        let totalInvestment = 0

        console.log("turnover",reports.monthlyTurnover)
        !IsEmptyObject(reports.monthlyTurnover) && reports.monthlyTurnover.map((resource, sl) => {
          let amount = resource.investment_emi_difference
          if (amount > 0) {
            totalProfit += amount
          } else {
            totalInvestment += (amount * -1)
          }
        });

        setTotalInvestment(totalInvestment)
        setTotalProfit(totalProfit)
      }
      }, [dispatch, reports]);
    
    return (
      <>
       
       <PageTitle title="Profit Calculator"/>
       
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="8">
              <DataList reports={reports}></DataList>
            </Col>
            <Col className="order-xl-1" xl="4">
             <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="card-stats mb-4 mb-xl-0 bg-gradient-gray border-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-white mb-0"
                        >
                          Total Investment
                        </CardTitle>
                        <span className="h1 font-weight-bold mb-0 text-white">
                          {totalInvestment}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-default text-white rounded-circle shadow">
                          <i className="fa fa-money-check-alt" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-white text-sm">
                      <span className="text-nowrap">Total invested amount</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>

              <Col className="order-xl-1 mt-2" xl="12">
                <Card className="card-stats mb-4 mb-xl-0 bg-gradient-default ">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-white mb-0"
                        >
                          Total Profit
                        </CardTitle>
                        <span className="h1 font-weight-bold mb-0 text-white">
                        {totalProfit}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-dark text-white rounded-circle shadow">
                          <i className="ni ni-money-coins" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-white text-sm">
                      <span className="text-nowrap">Total profit</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
             </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  };
  
  export default ProfitCalculator;
  