import {
  Button,
  Input,
  FormGroup,
  Form,
  Modal,
  Card,
  CardBody,
  InputGroupAddon,
  InputGroupText,
  InputGroup,


} from "reactstrap";
import React, { useEffect, useState } from 'react';
import ReactDatetime from "react-datetime";
import Select from 'react-select'

import { useDispatch, useSelector } from "react-redux";
import { setMessage } from 'slices/message';
import { resetApiError } from "slices/api";
import { nameValidator, emptyValidator } from "helpers/nameValidator";

import { fetchCustomerLoans } from "slices/loan.api";

import { addEmiPayment } from "slices/emi.api";
import LoanDetails from "views/partials/widgets/loan-details";
import { CheckPayment } from "components/Appolo/emi.query";
import { FormateDate } from "utils/helpers";
import CustomerSearch from "views/partials/customerSearch";

import PaymentModeSelector from "views/partials/widgets/payment-mode-selector";
import ListAgentFormWidget from "views/partials/widgets/list-agents";

const AddPaymentModel = (props) => {
  const { state, toggle } = props;


  const [customerLoanOptions, setCustomerLoanOptions] = useState([]);
  const [customerLoanDetails, setCustomerLoanDetails] = useState({})
  const [checkPaidStatus, setCheckPaidStatus] = useState("")

  const [customerID, setCustomerID] = useState({ value: '', error: '' });
  const [loanID, setLoanID] = useState({ value: '', error: '' });
  const [amount, setAmount] = useState({ value: '', error: '' });
  const [date, setDate] = useState({ value: '', error: '' });
  const [mode, setMode] = useState({ value: '', error: '',label:'' });
  const [agent, setAgent] = useState({ value: '', error: '',label:'' });

  const { apiError, addedNewEmiPayment, customerLoans } = useSelector((state) => state.api);
  const [checkPayment, { loading: cpLoading, data: cpData, err: cpErr }] = CheckPayment()

  const dispatch = useDispatch();
  // check there is an api error
  if (apiError !== undefined && apiError !== "") {
    dispatch(setMessage({
      "type": "error",
      "message": apiError
    }));
    dispatch(resetApiError());

  }

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    const validationVariables = [
      { name:"amount",state: amount, error: nameValidator(amount.value), setState: setAmount },
      { name:"customerID",state: customerID, error: nameValidator(customerID.value), setState: setCustomerID },
      { name:"loanID",state: loanID, error: nameValidator(loanID.value), setState: setLoanID },
      { name:"date",state: date, error: emptyValidator(date.value), setState: setDate },
      { name:"mode",state: mode, error: emptyValidator(mode.value), setState: setMode },
    ];

    let success = true
    for (const { state, error, setState,name } of validationVariables) {
      if (error) {
        console.log("error:::",error)
        console.log("setState:::",name)
        setState({ ...state, error });
        success = false
      }
    }

    if (!success) {
      return
    }

    const formattedDate = FormateDate(date.value);

    // add emi payment
    dispatch(addEmiPayment({
      amount_paid: parseInt(amount.value),
      "customer_id": customerID.value,
      "loan_id": loanID.value,
      date: formattedDate,
      mode: mode.value,
      agent_id: agent.value,
    }))

  };

  // onSelectCustomer
  const onSelectCustomer = selected => {
    setCustomerID({ value: selected.value, error: '' })
    setLoanID({ value: '', error: '' })
    setCustomerLoanOptions([])
    dispatch(fetchCustomerLoans({
      customerId: selected.value,
      status: "active",
    }))
  };


  const handleOnDateChange = (date) => {
    const formattedDate = FormateDate(date);
    checkPayment({
      variables: {
        "loanID": loanID.value,
        "action": "for-selected-month",
        "date": formattedDate,
      }
    })
  }
  // add new in
  useEffect(() => {
    if (addedNewEmiPayment === true) {
      dispatch(setMessage({
        "type": "success",
        "message": "You have successfully added new payment data"
      }));
      setTimeout(() => {
        window.location.reload();
      }, 1000)
    }
  }, [addedNewEmiPayment, dispatch]);


  // check the response
  useEffect(() => {
    if (cpData?.checkPayment?.status) {
      setCheckPaidStatus(cpData?.checkPayment?.value)
    }
  }, [cpData])

  // on change the customer, load the loans of that customer
  useEffect(() => {
    const mappedOptions = customerLoans.map((resource, index) => ({
      value: resource.loan.id,
      label: `${resource.loan.id} - ${resource.loan.title}`,
    }));
    setCustomerLoanOptions(mappedOptions);

    const customerLoanDetails = customerLoans.map((resource, index) => ({
      [resource.loan.id]: resource
    }));
    const combinedCustomerLoanDetails = Object.assign({}, ...customerLoanDetails);

    setCustomerLoanDetails(combinedCustomerLoanDetails)
  }, [customerLoans]);

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={state}
      toggle={toggle}
      size="lg"
    >
      <div className="modal-header">
        <h2 className="modal-title" id="exampleModalLabel">
          Add Payment
        </h2>
      </div>
      <div className="modal-body">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="p-0">
            <Form>
              <FormGroup className={customerID.error ? 'has-danger' : ''}>
                <CustomerSearch onCustomerSelect={onSelectCustomer} />
              </FormGroup>

              {
                customerLoans !== undefined && customerLoans.length > 0 && (
                  <Card className="card-profile shadow mb-2">
                    <CardBody className="pt-0 pt-md-4">
                      <div className="text-center">
                        <h3>
                          {customerLoans[0]?.customer.name}
                          <span className="font-weight-light">, {customerLoans[0].customer.customer_id}</span>
                        </h3>
                        <div className="h5 font-weight-300">
                          <i className="ni location_pin mr-2" />
                          {customerLoans[0]?.customer.id}
                        </div>
                        <div className="h5 mt-4">
                          <i className="ni business_briefcase-24 mr-2" />
                          {customerLoans[0].customer.contact_details}
                        </div>
                        <div>
                          <i className="ni education_hat mr-2" />
                          {customerLoans[0].customer.phone_number}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                )
              }

              <FormGroup className={loanID.error ? 'has-danger' : ''}>
                <Select options={customerLoanOptions} clearValue={true} onChange={(selected) => {
                  setLoanID({ value: selected.value, error: '' })
                }} />
              </FormGroup>

              {
                customerLoans !== undefined && customerLoans.length > 0 && loanID.value !== "" && customerLoanDetails[loanID.value] !== undefined && (
                  <Card className="card-profile shadow mb-2">
                    <CardBody className="pt-0 pt-md-4">
                      <LoanDetails loan={customerLoanDetails[loanID.value].loan} ></LoanDetails>
                    </CardBody>
                  </Card>
                )
              }

              <FormGroup className={amount.error ? 'has-danger' : ''}>
                <Input
                  className="form-control-alternative"
                  id="input-amount"
                  placeholder="Amount"
                  type="text"
                  onChange={(event) => {
                    setAmount({ value: event.target.value, error: '' })
                  }}
                />
                {
                  amount.error && (
                    <label className="text-danger p-1">{amount.error}</label>
                  )
                }
              </FormGroup>

              <PaymentModeSelector error={mode.error} onChange={(selected) => {
                setMode({ value: selected.value, error: '',label:selected.label  })
              }}></PaymentModeSelector>

              {/* Show agent */}
              {
                mode.label === "AGENT" && (
                  <ListAgentFormWidget error={agent.error} onChange={(selected) => {
                    setAgent({ value: selected.value, error: ''})
                  }} >
                  </ListAgentFormWidget>
                )
              }
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime

                    inputProps={{
                      placeholder: "Date Picker Here"
                    }}
                    timeFormat={false}
                    onChange={(date) => {
                      setDate({ value: date, error: '' })
                      handleOnDateChange(date)
                    }}
                    closeOnClickOutside={true}
                    closeOnSelect={true}
                  />
                </InputGroup>
                {
                  date.error && (
                    <label className="text-danger p-1">{date.error}</label>
                  )
                }
              </FormGroup>
              {
                checkPaidStatus !== "" && (
                  <FormGroup>
                    <p className="text-danger">You already have received a payment on <b>{FormateDate(checkPaidStatus)}</b> for this loan.</p>
                  </FormGroup>
                )
              }


              <div className="text-center">
                <Button color="default" type="button" onClick={handleSubmit}>
                  Add
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
}

export {
  AddPaymentModel
};
