import { FormGroup } from "reactstrap";
import Select from 'react-select'

const { ListAgents } = require("components/Appolo/agent.query")
const { useEffect, useState } = require("react");
const { useDispatch } = require("react-redux");

const ListAgentFormWidget = ({ onChange, error }) => {
  const dispatch = useDispatch();

  const [listAgents, { loading, data, err }] = ListAgents()
  const [options, setOptions] = useState([])

  useEffect(() => {
    const mappedOptions = data?.listAgents?.map((resource, index) => ({
      value: resource.id,
      label: `${resource.name}`,
    }));
    setOptions(mappedOptions);
  }, [data]);
  useEffect(() => {
    listAgents()
  }, [])

  return (
    <FormGroup className={error ? 'has-danger' : ''}>
      <Select options={options} clearValue={true} onChange={onChange} />
    </FormGroup>
  )
}

export default ListAgentFormWidget