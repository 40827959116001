import Select from 'react-select'
import React from 'react';
import { Col, FormGroup, Pagination, PaginationItem, PaginationLink, Row } from 'reactstrap'; // Import from your preferred UI library

const RenderPagination = (props) => {
  const options = [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ]
  const totalPages = props?.meta?.totalPages
  const currentPage = props?.meta?.currentPage
  const maxPagesToShow = 5; // Maximum number of pages to display at the start and end

  let startPage = 1;
  let endPage = totalPages;

  if (totalPages > maxPagesToShow) {
    if (currentPage <= maxPagesToShow - 2) {
      endPage = maxPagesToShow - 1;
    } else if (currentPage >= totalPages - maxPagesToShow + 3) {
      startPage = totalPages - maxPagesToShow + 2;
    } else {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
    }
  }

  const pages = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(
      <PaginationItem key={i} active={i === currentPage}>
        <PaginationLink onClick={() => props?.handlePageClick(i)}>
          {i}
        </PaginationLink>
      </PaginationItem>
    );
  }

  const renderDots = (position) => (
    <PaginationItem key={position} disabled>
      <PaginationLink >
        ...
      </PaginationLink>
    </PaginationItem>
  );

  return (
    totalPages > 0 && (
      <>
        {
          totalPages > 1 && (
            <Row>
              {/* <div className="col"> */}
              <div className="col text-left">
                  <Col md={props.limitWidgetClassSize ? props.limitWidgetClassSize : "3"}>
                    <FormGroup>
                      <Select 
                        options={options} 
                        clearValue={true} 
                        defaultValue={5 || 'Select'}
                        onChange={(selected) => {
                          if(props.onLimitSelected !== undefined ) {
                            props.onLimitSelected(selected.value)
                          }
                        }} 
                      />
                    </FormGroup>
                  </Col>

              </div>
              <div className="col text-center">
                <Pagination listClassName='pagination justify-content-center mb-0'>
                  <PaginationItem disabled={currentPage === 1}>
                    <PaginationLink
                      className='disable'
                      previous
                      onClick={() => {
                        if (currentPage > 1) {
                          props?.handlePageClick(currentPage - 1);
                        }
                      }}
                    />
                  </PaginationItem>
                  {startPage > 1 && renderDots('dotsStart')}
                  {pages}
                  {endPage < totalPages && renderDots('dotsEnd')}
                  <PaginationItem disabled={currentPage === totalPages}>
                    <PaginationLink
                      next
                      onClick={() => {
                        if (currentPage < totalPages) {
                          props?.handlePageClick(currentPage + 1);
                        }
                      }}
                    />
                  </PaginationItem>
                </Pagination>
              </div>
              {/* </div> */}
              <div className="col text-right">
                Found {props?.meta?.totalElements} items. {currentPage} of {totalPages}
              </div>
            </Row>
          )
        }
      </>
    )
  );
};

export default RenderPagination;
