import apiService from "./api.service"
import authHeader from "./auth-header";


// addInvestment
const addInvestment = (payload) => {
    return apiService.API.post("investment/add",payload,{
        headers: authHeader()
    })
}


// fetchinvestmentChart
const fetchinvestmentChart = (payload) => {
    return apiService.API.get("investment/chart?year="+payload?.year,{
        headers: authHeader()
    })
}


// fetchEmiChart
const fetchEmiChart = (payload) => {
    return apiService.API.get("emi/chart/"+payload,{
        headers: authHeader()
    })
}

const investmentService = {
    addInvestment,
    fetchinvestmentChart,
    fetchEmiChart,
}
export default investmentService
