import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Button,
  Media,
  FormGroup,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';

import { formatDate } from "utils/helpers";

import { fetchLoanList } from "slices/loan.api";
import { fetchCustomers } from "slices/customer.api";
import { fetchEmiPendingList } from "slices/emi.api";
import EmiActionTabs from "views/emi/tabs";
import PageTitle from "views/partials/pageTitle";
import CustomerSearch from "views/partials/customerSearch";

function EmiPendingList() {
  const dispatch = useDispatch();
  const [customerID, setCustomerID] = useState()

  const { customersList, loanList, emiPendingList } = useSelector((state) => state.api);
  const options = {
    "this-month": true,
  }
  // On filter clicked
  const handleFilter = () => {
    dispatch(
      fetchEmiPendingList({
        ...options,
        "customer-id": customerID,
      })
    )
  }

  // On clear clicked
  const handleClear = () => {
    dispatch(
      fetchEmiPendingList(
        options
      )
    )
  }

  useEffect(() => {
    dispatch(fetchEmiPendingList(options));
    dispatch(fetchLoanList());
    dispatch(fetchCustomers());
  }, [dispatch]);



  return (
    <>
      <PageTitle title="Pending EMI List" />

      {/* Page content */}
      <Container className="mt--7" fluid>

        <EmiActionTabs />

        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Emi Payment Pending / Upcoming List</h3>
                  </div>
                </Row>

                <Row className="mt-3">
                  <Col md="3">
                    <FormGroup>
                      <CustomerSearch onCustomerSelect={(selected) => {
                        setCustomerID(selected.value)
                      }} />
                    </FormGroup>
                  </Col>

                  <Col md="3">
                    <FormGroup>
                      <Button
                        className="btn-icon"
                        color="primary"
                        type="button"
                        size="md"
                        outline
                        onClick={handleFilter}
                      >Filter</Button>
                      <Button
                        className="btn-icon"
                        color="primary"
                        type="button"
                        size="md"
                        outline
                        onClick={handleClear}
                      >Clear</Button>
                    </FormGroup>
                  </Col>
                </Row>

              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Loan ID</th>
                    <th scope="col">Title</th>
                    <th scope="col">Customer ID</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Loan Amount</th>
                    <th scope="col">Re-payment Mode</th>
                    <th scope="col">Re-payment Amount</th>
                    <th scope="col">Due From </th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    emiPendingList !== undefined && emiPendingList.map((resource, index) => (
                      <tr key={index}>
                        <td>{++index}</td>
                        <td>
                          <span className="font-weight-bold">
                            <Button color="default" size="md" outline type="button">
                              {resource.loan.id}
                            </Button>
                          </span>
                        </td>
                        <td>
                          {resource.loan.title}
                        </td>
                        <td>
                          <Button color="default" size="md" type="button">
                            {resource.customer.customer_id}
                          </Button>
                        </td>
                        <td>

                          <Media className="align-items-center">
                            <a
                              className="avatar bg-white mr-3"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >

                              <img
                                alt="..."
                                src={require("../../assets/img/icons/user.png")}
                              />
                            </a>
                            <Media>
                              <span className="mb-0 text-sm">
                                {resource.customer.name}
                              </span>
                            </Media>
                          </Media>
                        </td>

                        <td>
                          <span className="font-weight-bold">
                            {resource.loan.loan_amount}
                          </span>
                        </td>
                        <td>
                          {resource.loan.emi_mode}
                        </td>
                        <td>

                          <h1 className="h3 text-danger" color="danger"> {resource.loan.monthly_emi}</h1>
                        </td>
                        <td>
                          <Button color="danger" size="md" outline type="button">
                            {formatDate(resource.due_date)}
                          </Button>
                        </td>
                        <td>
                          <a className="btn" href={`/admin/loans/detail/${resource.loan.id}`}>View</a>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>


      </Container>
    </>
  );
};

export default EmiPendingList;
