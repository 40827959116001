const { Table, Button, CardHeader, Row, Card } = require("reactstrap")

const RecentActivities = () => {
  return (
    <Card className="shadow">
      <CardHeader className="border-0">
        <Row className="align-items-center">
          <div className="col">
            <h3 className="mb-0">Recent Activities</h3>
          </div>
          <div className="col text-right">
            <Button
              color="primary"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
              size="sm"
            >
              See all
            </Button>
          </div>
        </Row>
      </CardHeader>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Activity</th>
            <th scope="col">Customer</th>
            <th scope="col">Amount</th>
            <th scope="col">Date</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">investment</th>
            <td>self</td>
            <td>
              <i className="fas fa-arrow-down text-warning mr-3" />{" "}
              15000
            </td>
            <td>
              10/09/2023
            </td>
          </tr>
          <tr>
            <th scope="row">Emai received</th>
            <td>John Doe</td>
            <td>
              <i className="fas fa-arrow-up text-success mr-3" />{" "}
              3000
            </td>
            <td>
              10/09/2023
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  )
}

export default RecentActivities