// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { useDispatch , useSelector} from "react-redux";
import { useEffect,useState } from "react";

import { fetchInvestmentList } from "slices/api";
import { fetchCustomers } from "slices/customer.api";
import { fetchTotalEmi,fetchEmiThisMonth, fetchEmiDueCount } from "slices/emi.api";
import { fetchEmiUpcomingMonth } from "slices/emi.api";
import { fetchTotalSales } from "slices/sales.api";

const Header = () => {
  const dispatch = useDispatch();

   const { customersList,totalEmi,totalEmiThisMonth, totalEmiUpcomingMonth, emiDueCount, totalSales } = useSelector((state) => state.api);

    const [investmentList, setInvestmentList] = useState([]);
    const [investmentListThisMonth, setInvestmentListThisMonth] = useState([]);


    useEffect(() => {
        dispatch(fetchCustomers());
        dispatch(fetchInvestmentList()).then((response) => {
          // setInvestmentList(response.payload.data);
        });
        dispatch(fetchInvestmentList({"this-month":true})).then((response) => {
          // setInvestmentListThisMonth(response.payload.data);
        });
  
        dispatch(fetchTotalEmi());
        dispatch(fetchEmiThisMonth());
        dispatch(fetchEmiUpcomingMonth());
        dispatch(fetchEmiDueCount());
        dispatch(fetchTotalSales());
    }, [dispatch]);

  return (
    <>
      <div className="header bg-gradient-gray-dark pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0 bg-gradient-primary border-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-white mb-0"
                        >
                          Customers
                        </CardTitle>
                        <span className="h1 font-weight-bold mb-0 text-white">
                          {customersList.length}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-indigo text-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-white text-sm">
                      <span className="text-nowrap">Total customers count</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0 bg-gradient-default border-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-white mb-0"
                        >
                          Total Instement
                        </CardTitle>
                        <span className="h1 font-weight-bold mb-0 text-white">{investmentList.amount}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-default text-white rounded-circle shadow">
                          <i className="fa fa-money-check-alt" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-white text-sm">
                      <span className="text-nowrap">Total invested amount</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0 bg-gradient-info border-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-white mb-0"
                        >
                          This Month investment
                        </CardTitle>
                        <span className="h1 font-weight-bold mb-0 text-white">{investmentListThisMonth.amount}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="fa fa-money-check-alt" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-white text-sm">
                      <span className="text-nowrap">Total invested amount on this month</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0 bg-gradient-danger border-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-white mb-0"
                        >
                          Total Sales
                        </CardTitle>
                        <span className="h1 font-weight-bold mb-0 text-white">{totalSales}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-poll" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-white text-sm">
                      <span className="text-nowrap">Total sales</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className="pt-5">  
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0 bg-gradient-dark  border-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-white mb-0"
                        >
                          Total EMI received
                        </CardTitle>
                        <span className="h1 font-weight-bold mb-0 text-white">{totalEmi}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-dark text-white rounded-circle shadow">
                          <i className="ni ni-money-coins" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-white text-sm">
                      <span className="text-nowrap">Total emi received</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0 bg-gradient-indigo border-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-white mb-0"
                        >
                          This Month
                        </CardTitle>
                        <span className="h1 font-weight-bold mb-0 text-white">{totalEmiThisMonth}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="ni ni-money-coins" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-white text-sm">
                      <span className="text-nowrap">Amount Received on this month</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0 bg-gradient-default border-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-white mb-0"
                        >
                          Upcoming Month
                        </CardTitle>
                        <span className="h1 font-weight-bold mb-0 text-white">{totalEmiUpcomingMonth}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="ni ni-money-coins" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-white text-sm">
                      <span className="text-nowrap">Amount should receive next month</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              {
                emiDueCount !== undefined && emiDueCount ? 
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 bg-yellow">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase mb-0"
                            >
                              EMI Due
                            </CardTitle>
                            <span className="h1 font-weight-bold mb-0">{emiDueCount}</span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-none text-danger rounded-circle">
                              <i className="fa fa-exclamation-triangle" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-nowrap">Total emi due for this month</span>
                          <a href="/admin/emi/due" className="btn btn-danger btn-sm float-right" > View</a>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                : ""
              }

            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
