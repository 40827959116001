import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Spinner,
  CardFooter,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import Select from 'react-select'

import { fetchCustomers } from "slices/customer.api";

import PageTitle from "views/partials/pageTitle";
import LoanTable from "./table";
import RenderPagination from "views/partials/pagination";
import LoanActionTabs from "./tabs";
import { GetLoans } from "components/Appolo/loan.query";
import { GetLoanCustomers } from "components/Appolo/loan.query";

function ClosedLoanList() {
  const dispatch = useDispatch();
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerID, setCustomerID] = useState()

  const [runQuery, { loading, data, err }] = GetLoans()
  const [callback, { loading:customerQLoading, data:customerQData, err:customerQErr }] = GetLoanCustomers()

  const [loanList, setLoanList] = useState([])
  const [customersList, setCustomerList] = useState([])
  const [page, setPage] = useState(1)
  const [meta, setMeta] = useState([])

  const queryVariables = {
    "page":page,
    "status":"closed"
  }
  // On filter clicked
  const handleFilter = () => {
    runQuery({ variables: {...queryVariables,"customer_id" : customerID} })
  }

  // On clear clicked
  const handleClear = () => {
    runQuery({ variables: {...queryVariables,"customer_id" : customerID} })
  }

  const onClick = (page) => {
    setPage(page)
    runQuery({ variables: { page: page } })
  }

  useEffect(() => {
    setLoanList(data?.loans?.list)
    setMeta(data?.loans?.meta_data)
  }, [data])
  useEffect(() => {
    setCustomerList(customerQData?.loanCustomers)
    const mappedOptions = customerQData?.loanCustomers?.map((resource, index) => ({
      value: resource.customer_id,
      label: `${resource.customer_id} - ${resource.name}`,
    }));
    setCustomerOptions(mappedOptions);
  }, [customerQData, customerQData?.loanCustomers])
  
  useEffect(() => {
    runQuery({ variables: {...queryVariables} })
    callback({variables: {...queryVariables}})
  }, [dispatch]);



  return (
    <>
      <PageTitle title="Investments"></PageTitle>

      {/* Page content */}
      <Container className="mt--7" fluid>

        <LoanActionTabs></LoanActionTabs>

        <Row className="">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Closed Loans</h3>
                  </div>
                </Row>

                <Row className="mt-3">
                  <Col md="3">
                    <FormGroup>
                      <Select options={customerOptions} clearValue={true} onChange={(selected) => {
                        setCustomerID(selected.value)
                      }} />
                    </FormGroup>
                  </Col>

                  <Col md="3">
                    <FormGroup>
                      <Button
                        className="btn-icon"
                        color="primary"
                        type="button"
                        size="md"
                        outline
                        onClick={handleFilter}
                      >Filter</Button>
                      <Button
                        className="btn-icon"
                        color="primary"
                        type="button"
                        size="md"
                        outline
                        onClick={handleClear}
                      >Clear</Button>
                    </FormGroup>
                  </Col>
                </Row>

              </CardHeader>
                {loading ? (
                  <div className="text-center">
                    <Spinner
                      size="sm"
                      type="grow"
                      className="text-default"
                    >
                      <span className=" sr-only">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  loanList && loanList.length > 0 ? (
                    <LoanTable page={page} loanList={loanList} meta={meta}></LoanTable>
                  ) : (
                    <div className="text-center">No data found</div>
                  )
                )}
              <CardFooter>
              <div className="col text-right">
                <RenderPagination meta={data?.loans?.meta_data} handlePageClick={onClick}></RenderPagination>
              </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>


      </Container>
    </>
  );
};

export default ClosedLoanList;
