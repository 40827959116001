import { ReleaseFund } from "components/Appolo/investment.query";
import { CancelFund } from "components/Appolo/investment.query";
import { useEffect, useState } from "react";
import {
  UncontrolledDropdown,
  Button
} from "reactstrap";
import Swal from "sweetalert2";

const InvestmentTableActions = ({ resource }) => {
  const[cancel, {loading, error, data}] = CancelFund()
  const[release, {loading:releaseLoading, error:releaseError, data:releaseData}] = ReleaseFund()

  const [releaseClicked, setCloseClicked] = useState(false)
  const [cancelClicked, setCancelClicked] = useState(false)


  const CancelFundAction = (id) => {
    Swal.fire({
      title: "Really want to cancel the fund?",
      input: "text",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Cancel Fund",
      showLoaderOnConfirm: true,
      preConfirm: async (notes) => {
        return {
          "id" : id,
          "notes" : notes,
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        cancel({
          variables : {
            id: result.value.id,
            notes: result.value.notes,
          }
        })
      }
    });
  }

  const ReleaseFundAction = (id) => {
    Swal.fire({
      title: "Really want to release the fund?",
      input: "text",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Release Fund",
      showLoaderOnConfirm: true,
      preConfirm: async (notes) => {
        return {
          "id" : id,
          "notes" : notes,
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        release({
          variables : {
            id: result.value.id,
            notes: result.value.notes,
          }
        })
      }
    });
  }

  useEffect(() => {
    if (releaseData !== undefined) {
      if (releaseData.releaseFund === true) {
        Swal.fire({
          icon: "success",
          title: "Released",
          text: "The investment has been released successfully!",
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000)
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops..!",
          text: "An unexpected error occured, we are unable to release the investment!",
        });
      }
    }
  },[releaseData])

  useEffect(() => {
    if (data !== undefined) {
      if (data.cancelFund === true) {
        Swal.fire({
          icon: "success",
          title: "Cancelled",
          text: "The investment has been cancelled successfully!",
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000)
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops..!",
          text: "An unexpected error occured, we are unable to cancel the investment!",
        });
      }
    }
  },[data])
  return (
    <UncontrolledDropdown>
      {
        resource.status.display_name === "Hold" ? (
          <>
            {/* <DropdownToggle
              className="btn-icon-only text-light"
              href="#pablo"
              role="button"
              size="sm"
              color=""
              onClick={(e) => e.preventDefault()}
            >
              <i className="fas fa-ellipsis-v" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem
                href="#"
              >
                {"Release Fund"}
              </DropdownItem>
            </DropdownMenu> */}
            <Button 
              className="btn-icon btn-2"
              color="primary" 
              type="button" 
              size="sm"
              onClick={(e) => {
                e.preventDefault()
                ReleaseFundAction(resource.id)
              }}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-settings" /> Release fund
              </span>
            </Button>

            <Button 
              className="btn-icon btn-2"
              color="primary" 
              type="button" 
              size="sm"
              onClick={(e) => {
                e.preventDefault()
                CancelFundAction(resource.id)
              }}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-fat-remove" /> Cancel fund
              </span>
            </Button>
          </>
        ) : "-"
      }
    </UncontrolledDropdown>
  );
}

export {
  InvestmentTableActions
};
