

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Table,
  Button,
  CardFooter,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { fetchLoanList } from "slices/loan.api";
import { formatDate } from "utils/helpers";
import LoanEmiDueDetails from "views/partials/emiDue";
import PageTitle from "views/partials/pageTitle";
import { statusColor } from "components/variables/variables";
import LoanActions from "./loan-actions";
import { GetEmiPayments } from "components/Appolo/emi.query";
import RenderPagination from "views/partials/pagination";
import { GetLoans } from "components/Appolo/loan.query";


const LoanDetails = () => {

  const [page, setPage] = useState(1)
  const [meta, setMeta] = useState([])
  const [pageLimit, setPageLimit] = useState(0)

  const variables = {
    thisMonth: false,
    page: page,
    limit: pageLimit,
  }

  const { loanList } = useSelector((state) => state.api);

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [runQuery, {loading, err, data}] = GetEmiPayments()
  const [loanQuery, {loading:loanLoad, err:loanErr, data:loanData}] = GetLoans()

  const { loanid } = useParams();
  const [totalEmiPaid, setTotalEmiPaid] = useState(0)
  const [emiList, setEmiList] = useState([])
  const [loanDetails, setLoanDetails] = useState({
    customer: {},
    loan: {},
    status_name:"active"
  })

  const handleLimitSelection = (count) => {
    runQuery({ variables: { ...variables,limit:count,page,loanID: loanid} })
    setPageLimit(count)
  }

  const onClick = (page) => {
    setPage(page)
    runQuery({ variables: { ...variables, page: page,loanID: loanid } })
  }


  const currentDate = new Date();
  useEffect(() => {
    if (loanid !== undefined) {
      runQuery({ variables: { ...variables, loanID: loanid } })
      loanQuery({
        variables: { 
          ...variables, 
          loan_id: loanid,
          "status": searchParams.get("status") !== null ? searchParams.get("status") : ""
         }
      })

    }
  }, [dispatch])


  useEffect(() => {
    setMeta(data?.payments?.meta_data)
    setEmiList(data?.payments?.list)
  },[data])

  useEffect(() => {
    setLoanDetails(loanData?.loans?.list[0])
    setTotalEmiPaid(loanData?.loans?.list[0].loan.total_amount_received);
  },[loanData])
  return (
    <>
      <PageTitle title="Loan Details"></PageTitle>

      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
            <img
                  alt="..."
                  className="card-img-top"
                  src={require("../../assets/img/theme/profile-bg.jpg")}
                />
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="rounded-circle"
                        src={require("../../assets/img/icons/client.png")}
                      />
                    </a>
                  </div>
                </Col>
              </Row>
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                <div className="d-flex justify-content-between">
                  <Button
                    className="btn-icon btn-3 mr-4"
                    color="default"
                    type="button">
                    <span className="btn-inner--icon">
                      <i className="fa fa-user" />
                    </span>
                    <span className="btn-inner--text">{loanDetails?.customer?.customer_id}</span>
                  </Button>
                  <Button
                    className="btn-icon btn-3 float-right"
                    color="default"
                    type="button">
                    <span className="btn-inner--icon">
                      <i className="fa fa-phone" />
                    </span>
                    <span className="btn-inner--text">{loanDetails?.customer?.phone_number}</span>
                  </Button>
                </div>
              </CardHeader>
              <CardBody className="pt-0 pt-md-4">
                <Row >
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                      <div className="col-md-3">
                        <Button color={statusColor[loanDetails?.status_name]} outline size="md">{loanDetails?.status_name}</Button>
                      </div>
                      <div className="col-md-3">
                        <span className="heading">{loanDetails?.loan?.monthly_emi} / {loanDetails?.loan?.emi_mode_value}</span>
                        <span className="description">EMI Amount</span>
                      </div>
                    </div>
                  </div>
                </Row>
                <Row className="border border-primary">
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center ">
                      <div className="col-md-3">
                        <span className="heading">{loanDetails?.loan?.loan_amount}</span>
                        <span className="description">Loan Amount</span>
                      </div>
                      <div className="col-md-3">
                        <span className="heading">{totalEmiPaid}</span>
                        <span className="description">Total Emi Paid</span>
                      </div>
                      <div className="col-md-3">
                        <span className="heading">{(loanDetails?.loan?.loan_amount ? loanDetails?.loan?.loan_amount : 0) - totalEmiPaid}</span>
                        <span className="description">balance</span>
                      </div>
                    </div>
                  </div>
                </Row>

                <Row >
                  <div className="col">
                    <div className="text-center mt-md-2">
                      <h3>
                        {loanDetails?.customer?.name}
                      </h3>
                      <div className="h5 font-weight-300">
                        <i className="ni location_pin mr-2" />
                        {loanDetails?.customer?.contact_details}
                      </div>
                      <div className="h3 mt-4text-info text-primary">
                        <i className="ni business_briefcase-24 mr-2 " />
                        Loan Started At : {formatDate(loanDetails?.loan?.loan_date)}
                      </div>
                    </div>
                  </div>
                </Row>

              </CardBody>
             
            </Card>
          </Col>




          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="6">

                    <h3 className="mb-0">Transactions - {loanDetails?.loan.title}</h3>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Row className="align-items-center">
                      <div className="col">
                        <LoanEmiDueDetails next_emi_date={loanDetails?.next_emi_date} currentDate={currentDate} ></LoanEmiDueDetails>
                      </div>
                      <div className="col text-right"> 
                        <LoanActions details={loanDetails}></LoanActions>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Loan Details</th>
                      <th scope="col">Amount Paid</th>
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      emiList !== undefined && emiList.map((resource, index) => (
                        <tr key={index}>
                          <td>{(++index)+ ((page - 1) * meta?.limit)}</td>
                          <td>
                            {resource.loan.id} - {resource.loan.title}
                          </td>
                          <td>
                            <i className="fas fa-arrow-up text-success mr-3"></i>
                            Rs {resource.emi_payment.amount_paid}
                          </td>
                          <td>
                            {formatDate(resource.emi_payment.payment_date)}
                          </td>
                          <td>
                            {/* <AssignedTableActions></AssignedTableActions> */}
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter>
                <div className="col text-right">
                  <RenderPagination 
                    meta={meta} 
                    handlePageClick={onClick} 
                    limitWidgetClassSize={6}
                    onLimitSelected={handleLimitSelection}
                  ></RenderPagination>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LoanDetails;
