const { Card, CardHeader, Row, Col, CardBody, Table } = require("reactstrap")
const { IsEmptyObject } = require("utils/helpers")

const DataList = ({reports}) => {
  return (
    <Card className="bg-secondary shadow">
      <CardHeader className="bg-white border-0">
        <Row className="align-items-center">
          <Col xs="8">
            <h3 className="mb-0">Monthly Earning and Investment</h3>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Month</th>
              <th scope="col">Invested</th>
              <th scope="col">Emi received</th>
              <th scope="col">Profit</th>
            </tr>
          </thead>
          <tbody>
            {
              !IsEmptyObject(reports.monthlyTurnover) && reports.monthlyTurnover.map((resource, sl) => (
                <tr key={sl}>
                  <td>
                    {++sl}
                  </td>
                  <td>
                    {
                      resource.month_name + " - " + resource.payment_year
                    }
                  </td>
                  <td>
                    <i className="fas fa-arrow-down text-danger mr-3" />
                    {
                      resource.total_investment
                    }
                  </td>
                  <td>
                    <i className="fas fa-arrow-up text-success mr-3" />
                    {
                      resource.total_emi_payments
                    }
                  </td>

                  <td>
                    {
                      <span>
                        {resource.investment_emi_difference}
                        {resource.investment_emi_difference > 0 ? (
                          <i className="fas fa-arrow-up text-success ml-3" />
                        ) : (
                          <i className="fas fa-arrow-down text-danger ml-3" />
                        )}
                      </span>
                    }
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
}

export default DataList