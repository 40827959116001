const { Card, CardHeader, Row, Col, Button, CardBody, Table } = require("reactstrap")
const { default: ViewImageModal } = require("views/partials/files/view-image-model")
const { default: StatusText } = require("views/partials/status")
const { default: UploadFileModel } = require("./UploadFile")
const { useState } = require("react")


const FilesListView = ({ files, customerid }) => {
  const [openModel, setOpenModel] = useState(false)
  const toggleModal = () => {
    setOpenModel(!openModel)
  };

  if(files === undefined) {
    return
  }

  return (
    <>
      {openModel && <UploadFileModel state={openModel} toggle={toggleModal} customerid={customerid} />}

      <Card className="bg-secondary shadow">
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">Files - {files?.length}</h3>
            </Col>
            <Col className="text-right" xs="4">
              <div className="col text-right">
                <Button
                  className="btn-icon"
                  color="primary"
                  type="button"
                  size="md"
                  outline
                  onClick={() => toggleModal()}
                >
                  <span className="btn-inner--icon">
                    <i className="ni ni-fat-add" />
                  </span>
                  <span className="btn-inner--text">Upload</span>
                </Button>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                files?.length > 0 ? (
                  files?.map((resource, index) => (
                    <tr key={index}>
                      <td>{++index}</td>
                      <td>
                        {resource.name}
                      </td>
                      <td>
                        {resource.type}
                      </td>

                      <td>
                        <StatusText status={resource.status} />
                      </td>
                      <td>
                        <ViewImageModal data={resource}></ViewImageModal>
                      </td>
                    </tr>
                  ))
                ) : (
                  <div className="text-center">No data found</div>
              )
              }
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  )
}

export default FilesListView