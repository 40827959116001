
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
const { gql } = require("@apollo/client");

const ActiveLoansCountGQL = gql`
    query get_active_loans_count {
        getActiveLoansCount
    }

`

// CloseLoanGQL
const CloseLoanGQL = gql`
  mutation close_loan($loanID: Int!) {
    closeLoan(loanID:$loanID)
  }

`
// CloseLoanGQL
const CancelLoanGQL = gql`
  mutation cancel_loan($loanID: Int!) {
    cancelLoan(loanID:$loanID)
  }

`
// ActiveLoansGQL
const ActiveLoansGQL = gql`
  query ActiveLoans($customer_id: String, $page: Int!) {
    loans(
      status:"active"
      filter: { customer_id: $customer_id }
      pagination: { page: $page, limit: 0 }
    ) {
        list {
          customer {
              id
              customer_id
              name
              contact_details
              phone_number
              notes
          }
          loan {
              id
              customer_id
              title
              loan_amount
              emi_mode
              monthly_emi
              loan_date
              emi_mode_value
              total_amount_received
              status
          }
          no_of_emi_paid
          next_emi_date
        }
        meta_data {
          currentPage
          currentElements
          totalPages
          totalElements
        }
    }
  }
`;

// LoansGQL
const LoansGQL = gql`
  query GetLoans($status: String!,$customer_id: String, $page: Int!, $customer_uid: String, $this_month: Boolean,$loan_id: Int) {
    loans(
      status:$status
      filter: { customer_id: $customer_id, customer_uid:$customer_uid, this_month:$this_month, loan_id:$loan_id }
      pagination: { page: $page, limit: 0 }
    ) {
        list {
          customer {
              id
              customer_id
              name
              contact_details
              phone_number
              notes
          }
          loan {
              id
              customer_id
              title
              loan_amount
              emi_mode
              monthly_emi
              loan_date
              emi_mode_value
              total_amount_received
              status
          }
          no_of_emi_paid
          next_emi_date
          status_name
        }
        meta_data {
          limit
          currentPage
          currentElements
          totalPages
          totalElements
        }
    }
  }
`;

// LoanCustomersGQL
const LoanCustomersGQL = gql`
  query loanCustomers($status: String!) {
    loanCustomers(status:$status) {
      id
      customer_id
      name
      contact_details
      phone_number
    }
  }
`

const SearchCustomerLoansGQL = gql`
  query search_customer_loans($customerName: String) {
    searchCustomerLoans(customerName:$customerName) {
      customer {
        id
        name
        customer_id
      }
      loan {
        id
        title
      }
    }
  }
`
const LoansCountByMonthGQL = gql`
  query GetLoansByMonth($year: Int) {
  getLoansByMonth(filter:{year:$year}) {
    year
    month
    count
    monthName
  }
}
`
// GetActiveLoansCount
const GetActiveLoansCount = () => {
  const { loading, error, data } = useQuery(ActiveLoansCountGQL);
  return { loading, error, data };
}

// CloseLoan
const CloseLoan = () => {
  const [mutateFunction, { loading, error, data }] = useMutation(CloseLoanGQL);
  return [mutateFunction, { loading, error, data }];
}
// CancelLoanGQL
const CancelLoan = () => {
  const [mutateFunction, { loading, error, data }] = useMutation(CancelLoanGQL);
  return [mutateFunction, { loading, error, data }];
}

// GetActiveLoans
const GetActiveLoans = () => {
  const [runQuery, { loading, error, data }] = useLazyQuery(ActiveLoansGQL);
  return [runQuery, { loading, error, data }];
}

// GetLoans
const GetLoans = () => {
  const [runQuery, { loading, error, data }] = useLazyQuery(LoansGQL);
  return [runQuery, { loading, error, data }];
}

// GetLoans
const GetLoanCustomers = () => {
  const [runQuery, { loading, error, data }] = useLazyQuery(LoanCustomersGQL);
  return [runQuery, { loading, error, data }];
}
// SearchCustomerLoans
const SearchCustomerLoans = () => {
  const [runQuery, { loading, error, data }] = useLazyQuery(SearchCustomerLoansGQL);
  return [runQuery, { loading, error, data }];
}

// GetLoanscount by month
const GetLoansCountByMonth = (year) => {
  const [runQuery, { loading, error, data }] = useLazyQuery(LoansCountByMonthGQL);
  return [runQuery, { loading, error, data }];
}


// export
export {
  ActiveLoansGQL,
  GetActiveLoansCount,
  CloseLoan,
  CancelLoan,
  GetActiveLoans,
  GetLoans,
  GetLoanCustomers,
  SearchCustomerLoans,
  GetLoansCountByMonth
}