import { createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import emiService from "services/emi.services";


// Add New Emi Payment
export const addEmiPayment = createAsyncThunk(
  "emi/add",
  async (IPdata, thunkAPI) => {
    try {
      const data = await emiService.addEmiPayment(IPdata);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      // Check the response status code
      const statusCode = error.response && error.response.status;
      console.log("Response status code:", statusCode);

      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

// Loan details
export const fetchTotalEmi = createAsyncThunk(
    "emi/total",
    async (_, thunkAPI) => {
      try {
        const data = await emiService.fetchTotalEmi();
        return data;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        // Check the response status code
        const statusCode = error.response && error.response.status;
        console.log("Response status code:", statusCode);
  
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
);
  
// fetchEmiThisMonth
export const fetchEmiThisMonth = createAsyncThunk(
  "emi/total/this-month",
  async (_, thunkAPI) => {
    try {
      const data = await emiService.fetchTotalEmi({
        "this_month" : true,
      });
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      // Check the response status code
      const statusCode = error.response && error.response.status;
      console.log("Response status code:", statusCode);

      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

// fetchEmiUpcomingMonth
export const fetchEmiUpcomingMonth = createAsyncThunk(
  "emi/total/upcoming-month",
  async (_, thunkAPI) => {
    try {
      const data = await emiService.fetchTotalEmi({
        "upcoming_month" : true,
      });
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      // Check the response status code
      const statusCode = error.response && error.response.status;
      console.log("Response status code:", statusCode);

      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const fetchEMIList = createAsyncThunk(
  "emi/list",
  async (ipdata, thunkAPI) => {
    try {
      const data = await emiService.fetchEmiList(ipdata);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      // Check the response status code
      const statusCode = error.response && error.response.status;
      console.log("Response status code:", statusCode);

      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

// fetchCustomerEmiPayments
export const fetchCustomerEmiPayments = createAsyncThunk(
  "emi/list/customer",
  async (IPData, thunkAPI) => {
    try {
      const data = await emiService.fetchCustomerEmiPayments(IPData);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      // Check the response status code
      const statusCode = error.response && error.response.status;
      console.log("Response status code:", statusCode);

      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


// fetchEmidueList
export const fetchEmidueList = createAsyncThunk(
  "emi/due/list",
  async (_, thunkAPI) => {
    try {
      const data = await emiService.fetchEmiDueList();
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      // Check the response status code
      const statusCode = error.response && error.response.status;
      console.log("Response status code:", statusCode);

      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const fetchEmiPendingList = createAsyncThunk(
  "emi/due/list/pending",
  async (Ipdata, thunkAPI) => {
    try {
      const data = await emiService.fetchEmiDueList(Ipdata);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      // Check the response status code
      const statusCode = error.response && error.response.status;
      console.log("Response status code:", statusCode);

      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

// fetchEmiDueCount
export const fetchEmiDueCount = createAsyncThunk(
  "emi/due/count",
  async (_, thunkAPI) => {
    try {
      const data = await emiService.fetchEmiDueCount();
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      // Check the response status code
      const statusCode = error.response && error.response.status;
      console.log("Response status code:", statusCode);

      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
