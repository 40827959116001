import {
  Badge,
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Media,
  FormGroup,
  CardFooter,

} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import Select from 'react-select'

import { fetchCustomers } from "slices/customer.api";
import { AddNewCustomerModel } from "./Add";
import PageTitle from "views/partials/pageTitle";
import { GetCustomers } from "components/Appolo/customer.query";
import RenderPagination from "views/partials/pagination";
import CustomerSearch from "views/partials/customerSearch";
import Filter from "views/partials/widgets/filter";

function CustomerList() {
  const dispatch = useDispatch();
  const variables = {
    page: 1
  }
  const [customersList, setCustomersList] = useState([])
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerID, setCustomerID] = useState()
  const [meta, SetMeta] = useState([])
  const [openModel, setOpenModel] = useState(false)

  const [runQuery, { loading, error, data }] = GetCustomers()

  useEffect(() => {
    runQuery({ variables: { ...variables } })
  }, [])

  useEffect(() => {
    let list = data?.customers?.list
    setCustomersList(list)
    SetMeta(data?.customers?.meta_data)
  }, [data])

  const toggleModal = () => {
    setOpenModel(!openModel)
  };



  // On filter clicked
  const handleFilter = () => {
    runQuery({ variables: { ...variables, customerID: customerID } })
  }

  // On clear clicked
  const handleClear = () => {
    runQuery({ variables: { ...variables } })
  }
  const handlePaginationClick = (page) => {
    runQuery({ variables: { ...variables, page: page } })
  }

  // onChooseFilterOption
  const onChooseFilterOption = (option) => {
    switch (option) {
      case "all" : 
        variables.thisMonth = false
      break;
      case "this-month" : 
      variables.thisMonth = true
    break;
      default:
        break;
    }
    runQuery({
      variables: { ...variables }
    })
  }

  return (
    <>
      {openModel && <AddNewCustomerModel state={openModel} toggle={toggleModal} />}

      <PageTitle title="Customers" />




      {/* Page content */}
      <Container className="mt--7" fluid>


        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Customers</h3>
                  </div>

                  <div className="col text-right">
                  <Filter onChooseFilterOption={onChooseFilterOption}></Filter>

                    <Button
                      className="btn-icon"
                      color="secondary"
                      type="button"
                      size="md"
                      onClick={() => toggleModal()}
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-fat-add" />
                      </span>
                      <span className="btn-inner--text">Add</span>
                    </Button>
                  </div>
                </Row>

                {/* FIlter */}
                <Row className="mt-3">
                  <Col md="3">

                    <FormGroup>
                      <CustomerSearch onCustomerSelect={(selected) => {
                        setCustomerID(selected.value)
                      }} onCustomer={true} />
                    </FormGroup>
                  </Col>

                  <Col md="3">
                    <FormGroup>
                      <Button
                        className="btn-icon"
                        color="primary"
                        type="button"
                        size="md"
                        outline
                        onClick={handleFilter}
                      >Filter</Button>
                      <Button
                        className="btn-icon"
                        color="primary"
                        type="button"
                        size="md"
                        outline
                        onClick={handleClear}
                      >Clear</Button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Customer ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Contact Details</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    customersList?.map((resource, index) => (
                      <tr key={index}>
                        <td>
                          <Button size="md" color="default" type="button">
                            {resource.customer_id}
                          </Button>
                        </td>
                        <th scope="row">
                          <Media className="align-items-center">
                            <a
                              className="avatar bg-white mr-3"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                src={require("../../assets/img/icons/user.png")}
                              />
                            </a>
                            <Media>
                              <span className="mb-0 text-sm">
                                {resource.name}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>
                          <Badge color="" className="badge-dot mr-4">
                            {resource.contact_details}
                          </Badge>
                        </td>
                        <td>
                          {resource.phone_number}
                        </td>
                        <td>
                          <a className="btn" href={`/admin/customer/details/${resource.id}`}>View</a>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
              <CardFooter>
                <div className="col text-right">
                  <RenderPagination meta={meta} handlePageClick={handlePaginationClick}></RenderPagination>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>


      </Container>
    </>
  );
};

export default CustomerList;
