import { useState } from "react";
import { Button, Modal, ModalFooter } from "reactstrap"

const ViewImageModal = ({ data }) => {
  const [openModel, setOpenModel] = useState(false)
  const toggleModal = () => {
    setOpenModel(!openModel)
  };
  const [rotationDegree, setRotationDegree] = useState(0);
  const dynamicStyle = {
    transform: [`rotate(${rotationDegree}deg)`],
  };


  const rotate = (position) => {
    let degree = rotationDegree + 90
    if (position === "left") {
      degree = rotationDegree - 90
    }

    if (degree > 360 || degree < -360) {
      console.log("yes here")
      degree = 0
    }
    console.log("degree---", degree)
    console.log("rotationDegree---", rotationDegree)
    console.log("rotationDegree + 90---", rotationDegree + 90)
    setRotationDegree(degree)
  }
  return (
    <>
      <Button outline onClick={() => toggleModal()}>
        View
      </Button>
      {openModel && (
        <Modal
          className="modal-dialog-centered shadow-none bg-none"
          isOpen={openModel}
          toggle={toggleModal}
          size="lg"
        >
          <div className="modal-header border">
            <h2 className="modal-title" id="exampleModalLabel">
              {data.name}
            </h2>
          </div>
          <div className="modal-body bg-none text-center">
            <img
              alt="..."
              width={"50%"}
              className="img-thumbnail"
              src={data.path}
              style={dynamicStyle}
            />
          </div>
          <ModalFooter className="border">
            <Button className="btn-icon btn-3" color="primary" type="button" onClick={() => { rotate("left") }}>
              <span className="btn-inner--icon">
                <i className="fa fa-rotate-left" />
              </span>
              <span className="btn-inner--text" ></span>
            </Button>

            <Button className="btn-icon btn-3" color="primary" type="button" onClick={() => { rotate("right") }}>
              <span className="btn-inner--icon">
                <i className="fa fa-rotate-right" />
              </span>
              <span className="btn-inner--text" ></span>
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>

  )
}


export default ViewImageModal