import apiService from "./api.service"
import authHeader from "./auth-header";


const fetchMonthlyTurnover = (filter) => {
    let endpoint = "reports/monthly-turnover"
    if (filter.Latest !== undefined) {
        endpoint = "reports/monthly-turnover-v2"
    }
    console.log(endpoint)
    return apiService.API.get(endpoint,{
        headers: authHeader()
    })
}

const reportService = {
    fetchMonthlyTurnover,
}
export default reportService
