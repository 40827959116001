import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
const { gql } = require("@apollo/client");


const GetInvestments = gql`
    query get_investments($this_month: Boolean!, $page: Int!, $status: Int){
      getInvestments(filter:{
          ThisMonth:$this_month,
          status:$status
      }, pagination:{
          page: $page
          limit: 0
      }) {
          list {
            aggreagtedAmount
            detailed {
              id
              amount
              investedOn
              investedVia
              isCapital
              status {
                id
                display_name
              }
              notes
              additionalNotes
              ref
            }
          }
          meta_data {
            limit
            currentPage
            currentElements
            totalPages
            totalElements
          }
      }
    }
`

const TotalInvestmentGQL = gql`
    query get_total_investments($thisMonth: Boolean!, $status: Int){
        totalInvestments(filter : {
          ThisMonth: $thisMonth, 
          status:$status
        })
    }
`

const AddInvestmentGQL = gql`
  mutation add_investment(
    $investedOn: String!,
    $amount: Int!,
    $via: String!,
    $status: Int!,
    $loanID: Int,
    $notes: String!,
    $agentID: String,
    $onAgent: Boolean
  ) {
    addInvestment(
      investedOn:$investedOn,
      amount:$amount,
      via:$via,
      status:$status,
      loanID:$loanID,
      notes:$notes,
      agentID: $agentID,
      onAgent: $onAgent
    ) {
      id
      amount
      invested_via
    }
  }
`

const ReleaseFundGQL = gql`
  mutation release_fund($id: Int!, $notes: String) {
    releaseFund(id:$id,notes:$notes)
  }
`

const CancelFundGQL = gql`
  mutation cancel_fund($id: Int!, $notes: String) {
    cancelFund(id:$id,notes:$notes)
  }
`
const GetTotalInvestments = () => {
    const [runQuery, { loading, error, data }] = useLazyQuery(TotalInvestmentGQL);
    return [runQuery, { loading, error, data }];
}

// AddInvestment
const AddInvestment = () => {
    const [mutateFunction,{ loading, error, data }] = useMutation(AddInvestmentGQL);
    return [mutateFunction,{ loading, error, data }];
}

// Release fund
const ReleaseFund = () => {
  const [mutateFunction,{ loading, error, data }] = useMutation(ReleaseFundGQL);
  return [mutateFunction,{ loading, error, data }];
}

// Cancel fund
const CancelFund = () => {
  const [mutateFunction,{ loading, error, data }] = useMutation(CancelFundGQL);
  return [mutateFunction,{ loading, error, data }];
}



export {
    GetInvestments,
    GetTotalInvestments,
    AddInvestment,
    ReleaseFund,
    CancelFund
}