const { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } = require("reactstrap")

const Filter = ({ onChooseFilterOption }) => {
  const onSelect = (option) => {
    if (onChooseFilterOption !== undefined || onChooseFilterOption !== null) {
      onChooseFilterOption(option)
    }
  }
  return (
    <UncontrolledDropdown>
      <DropdownToggle
        caret
        color="default"
        id="dropdownMenuButton"
        type="button"
      >
        Filter
      </DropdownToggle>
      <DropdownMenu aria-labelledby="dropdownMenuButton">
        <DropdownItem  onClick={() => {
          onSelect("all")
        }}>
          <i className=" fa fa-list-alt"></i>
          <span>All</span>
        </DropdownItem>
        <DropdownItem className=""  onClick={() => {
          onSelect("this-month")
        }}>
          <i className=" ni ni-calendar-grid-58"></i>
          <span>This Month</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>

    // <UncontrolledDropdown direction="down">
    //     <DropdownToggle color="primary" role="button">
    //       <span>Click me</span>
    //     </DropdownToggle>
    //     <DropdownMenu>
    //       <DropdownItem>
    //         <i className=" ni ni-single-02"></i>
    //         <span>My profile</span>
    //       </DropdownItem>
    //       <DropdownItem>
    //         <i className=" ni ni-settings-gear-65"></i>
    //         <span>Settings</span>
    //       </DropdownItem>
    //       <DropdownItem>
    //         <i className=" ni ni-calendar-grid-58"></i>
    //         <span>Activity</span>
    //       </DropdownItem>
    //       <DropdownItem>
    //         <i className=" ni ni-support-16"></i>
    //         <span>Support</span>
    //       </DropdownItem>
    //       <DropdownItem divider></DropdownItem>
    //       <DropdownItem>
    //         <i className=" ni ni-user-run"></i>
    //         <span>Logout</span>
    //       </DropdownItem>
    //     </DropdownMenu>
    //   </UncontrolledDropdown>
  )
}

export default Filter