import { Chart } from "chart.js";
import { EmiPaymentSum } from "components/Appolo/emi.query";

const { Line, Bar } = require("react-chartjs-2")
const { Card, CardHeader, Row, Nav, NavItem, CardBody, NavLink, Button } = require("reactstrap")
const classnames = require("classnames");
const { useState, useEffect } = require("react");
const { emiPaymentChart } = require("variables/charts");
const { parseOptions } = require("variables/charts");
const { chartOptions } = require("variables/charts");


const EmiPaymentsChart = () => {
  const [runQuery, { loading, err, data }] = EmiPaymentSum()

  const [activeNav, setActiveNav] = useState("line");
  const [chartData, setChartData] = useState([]);
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
  };

  const emiChartListData = (canvas) => {
    return chartData;
  }

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  useEffect(() => {
    if (data?.emiPaymentsSum !== undefined) {
      // const emi_labels = (data?.emiPaymentsSum || []).map(value => value.month_name);
      // const emi_data = (data?.emiPaymentsSum || []).map(value => value.total);

      // Or using reduce (single iteration over the array)
      const { emi_labels, emi_data } = (data?.emiPaymentsSum || []).reduce((acc, value) => {
        acc.emi_labels.push(value.month_name);
        acc.emi_data.push(value.total);
        return acc;
      }, { emi_labels: [], emi_data: [] });


      setChartData({
        labels: emi_labels,
        datasets: [
          {
            label: "Performance",
            data: emi_data,
            maxBarThickness: 10,
          },
        ],
      })
    }
  }, [data])

  useEffect(() => {
    runQuery()
  }, [])

  return (
    <Card className="bg-gradient-default shadow">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h6 className="text-uppercase text-light ls-1 mb-1">
              Overview
            </h6>
            <h2 className="text-white mb-0">EMI Payment Graph</h2>
          </div>
          <div className="col">
            <Nav className="justify-content-end">
              <NavItem>
                <NavLink
                  className={classnames("py-2 px-2", {
                    active: activeNav === "line",
                  })}
                  href="#pablo"
                  onClick={(e) => toggleNavs(e, "line")}
                >
                  <Button
                    className="btn-icon btn-3"
                    color={activeNav === "line" ? "primary" : ""}
                    type="button"
                    onClick={(e) => toggleNavs(e, "line")}
                  >
                    <span className="btn-inner--icon">
                      <i className="fa fa-line-chart" />
                    </span>
                    {/* <span className="btn-inner--text">Line</span> */}
                  </Button>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("py-2 px-3", {
                    active: activeNav === "bar",
                  })}
                  data-toggle="tab"
                  href="#pablo"
                  onClick={(e) => toggleNavs(e, "bar")}
                >

                  <Button
                    className="btn-icon btn-3"
                    color={activeNav === "bar" ? "primary" : ""}
                    type="button"
                    onClick={(e) => toggleNavs(e, "bar")}
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-chart-bar-32" />
                    </span>
                    {/* <span className="btn-inner--text">Bar</span> */}
                  </Button>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        {/* Chart */}
        <div className="chart">
          {
            activeNav === "line" ? (
              <Line
                data={emiChartListData}
                options={emiPaymentChart.options}
                getDatasetAtEvent={(e) => console.log(e)}
              />
            ) : (
              <Bar
                data={emiChartListData}
                options={emiPaymentChart.options}
                getDatasetAtEvent={(e) => console.log(e)}
              />
            )
          }
        </div>
      </CardBody>
    </Card>
  )
}

export default EmiPaymentsChart