

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Table,
  Button,
} from "reactstrap";

import { useDispatch } from "react-redux";
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { formatDate } from "utils/helpers";
import PageTitle from "views/partials/pageTitle";
import StatusText from "views/partials/status";
import { GetCustomerDetails } from "components/Appolo/customer.query";
import FilesListView from "./files-list";

const CustomerDetails = () => {

  const dispatch = useDispatch();
  // const {  customerDetails } = useSelector((state) => state.api);
  const [customerDetails, setCustomerDetails] = useState([])

  const { customerid } = useParams();

  const [runQuery, { loading, err, data }] = GetCustomerDetails()

 


  useEffect(() => {
    runQuery({
      variables: {
        customer_id: customerid
      }
    })
  }, [customerid, dispatch, runQuery])


  useEffect(() => {
    setCustomerDetails(data?.customerDetails)
  }, [data])

  return (
    <>
      <PageTitle title="Loan Details"></PageTitle>

      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="rounded-circle"
                        src={require("../../assets/img/icons/client.png")}
                      />
                    </a>
                  </div>
                </Col>
              </Row>
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                <div className="d-flex justify-content-between">
                  <Button
                    className="btn-icon btn-3 mr-4"
                    color="default"
                    type="button">
                    <span className="btn-inner--icon">
                      <i className="fa fa-user" />
                    </span>
                    <span className="btn-inner--text">{customerDetails?.customer?.customer_id}</span>
                  </Button>
                  <Button
                    className="btn-icon btn-3 float-right"
                    color="default"
                    type="button">
                    <span className="btn-inner--icon">
                      <i className="fa fa-phone" />
                    </span>
                    <span className="btn-inner--text">{customerDetails?.customer?.phone_number}</span>
                  </Button>
                </div>
              </CardHeader>
              <CardBody className="pt-0 pt-md-4">
                <Row >
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                      <div className="col-md-6">
                        <span className="heading">{customerDetails?.customer?.name}</span>
                        <span className="description">{customerDetails?.customer?.contact_details}</span>
                      </div>
                    </div>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>




          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Loans - {customerDetails?.loans?.length}</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Loan Details</th>
                      <th scope="col">Loan Amount</th>
                      <th scope="col">Monthly EMI</th>
                      <th scope="col">Loan Date</th>
                      <th scope="col">Total Paid</th>
                      <th scope="col">Status</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      customerDetails?.loans?.map((resource, index) => (
                        <tr key={index}>
                          <td>{++index}</td>
                          <td>
                            {resource.id} - {resource.title}
                          </td>
                          <td>
                            Rs {resource.loan_amount}
                          </td>
                          <td> Rs {resource.monthly_emi} </td>
                          <td>
                            {formatDate(resource.loan_date)}
                          </td>
                          <td> Rs {resource.total_amount_received} </td>
                          <td>
                            <StatusText status={resource.status} />
                          </td>
                          <td>
                            <a className="btn" href={`/admin/loans/detail/${resource.id}`}>View</a>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl="8">
            <FilesListView customerid={customerid} files={customerDetails?.files}></FilesListView>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CustomerDetails;
