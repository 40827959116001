

import {
    Container,
  } from "reactstrap";


const PageTitle = ({title}) => {
    return (
        <div
            className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
            style={{
            minHeight: "300px",
            backgroundImage:
                "url(" + require("../../assets/img/theme/background.jpg") + ")",
            backgroundSize: "cover",
            backgroundPosition: "center top",
            }}
        >
            {/* Mask */}
            <span className="mask bg-gradient-default opacity-8 text-center" />
            {/* Header container */}
            {/* <Container className="d-flex align-items-center" fluid> */}
            <Container className="text-center align-items-center" fluid>
            {/* <Row>
                <Col lg="12" md="12">
                    <h1 className="display-2 text-white">{title}</h1>
                </Col>
            </Row> */}
            </Container>
        </div>
    )
}

export default PageTitle