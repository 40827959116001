import {
    Button,
    Input,
    FormGroup,
    Form,
    Modal,
    Card,
    CardBody
} from "reactstrap";
import React, { useEffect, useState } from 'react';
import { useDispatch , useSelector} from "react-redux";
import { setMessage } from 'slices/message';
import { resetApiError } from "slices/api";
import { addNewCustomer, fetchCustomers } from "slices/customer.api";
import { nameValidator } from "helpers/nameValidator";

const AddNewCustomerModel = (props) => {
    const { state, toggle } = props;

    const [name, setName] = useState({ value: '', error: '' });
    const [phone, setPhone] = useState({ value: '', error: '' });
    const [contact, setContact] = useState({ value: '', error: '' });

    const { apiError, addedNewCustomer } = useSelector((state) => state.api);
    const dispatch = useDispatch();
    // check there is an api error
   if (apiError !== undefined && apiError !== "") {
    console.log("apiError---",apiError)
      dispatch(setMessage({
        "type" : "error",
        "message" : apiError
      }));
      dispatch(resetApiError());

   }
   // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    
    const nameError = nameValidator(name.value)
    const phoneError = nameValidator(phone.value)
    const contactError = nameValidator(contact.value)
    
    if (nameError || phoneError || contactError) {
      setName({ ...name, error: nameError })
      setPhone({ ...phone, error: phoneError })
      setContact({ ...contact, error: contactError })
      return
    }
    
    dispatch(addNewCustomer({
        "name" : name.value,
        "phone_number" : phone.value,
        "contact" : contact.value,
    }))
    
  };

    useEffect(() => {
        if (addedNewCustomer === true) {
            dispatch(setMessage({
                "type" : "success",
                "message" : "You have successfully added new customer"
            }));
            setTimeout(() => {
                window.location.reload();
            },1000)
        }
    }, [addedNewCustomer, dispatch]);

    useEffect(() => {
        dispatch(fetchCustomers());
    }, [dispatch,]);

    return (
        <Modal
          className="modal-dialog-centered"
          isOpen={state}
          toggle={toggle}
          size="md"
        >
            <div className="modal-header">
                <h2 className="modal-title" id="exampleModalLabel">
                    Add New customer
                </h2>
            </div>
              <div className="modal-body">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="p-0">
                        <Form>
                            <FormGroup className={name.error ? 'has-danger' : ''}>
                                <Input
                                className="form-control-alternative"
                                id="input-name"
                                placeholder="Name"
                                type="text"
                                onChange={(event) => setName({ value: event.target.value, error: '' })}
                                />
                                {
                                    name.error && (
                                        <label className="text-danger p-1">{name.error}</label>
                                    )
                                }
                            </FormGroup>
                            <FormGroup className={phone.error ? 'has-danger' : ''}>
                                <Input
                                className="form-control-alternative"
                                id="input-phone"
                                placeholder="Phone Number"
                                type="text"
                                onChange={(event) => setPhone({ value: event.target.value, error: '' })}
                                />
                                {
                                    phone.error && (
                                        <label className="text-danger p-1">{phone.error}</label>
                                    )
                                }
                            </FormGroup>

                            <FormGroup className={contact.error ? 'has-danger' : ''}>
                                <Input
                                className="form-control-alternative"
                                id="input-contact"
                                placeholder="Contact Details"
                                rows="3"
                                type="textarea"
                                onChange={(event) => setContact({ value: event.target.value, error: '' })}
                                />
                                {
                                    contact.error && (
                                        <label className="text-danger p-1">{contact.error}</label>
                                    )
                                }
                            </FormGroup>

                            {/* <FormGroup className={contact.error ? 'has-danger' : ''}>
                                <Input
                                className="form-control-alternative"
                                id="input-files"
                                placeholder="Files"
                                type="file"
                                multiple="true"
                                // onChange={(event) => setContact({ value: event.target.value, error: '' })}
                                />
                                {
                                    contact.error && (
                                        <label className="text-danger p-1">{contact.error}</label>
                                    )
                                }
                            </FormGroup> */}

                            <div className="text-center">
                                <Button color="default" type="button" onClick={handleSubmit}>
                                    Add
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </div>
        </Modal>
    );
}

export {
    AddNewCustomerModel
};
