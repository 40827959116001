import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button,
  FormGroup,
  CardFooter,
  Badge,
} from "reactstrap";
import Select from 'react-select'

import { useDispatch } from "react-redux";
import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";

import { AddInvestmentModel } from "./add";
import { formatDate } from "utils/helpers";
import PageTitle from "views/partials/pageTitle";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GetInvestments } from "components/Appolo/investment.query";
import { InvestmentTableActions } from "components/Dropdowns/InvestmentTableActions";
import RenderPagination from "views/partials/pagination";
import { GetStatusByCategory } from "components/Appolo/status.query";
import Filter from "views/partials/widgets/filter";

const options = [
  { value: "this-month", label: "This Month" }
]

const StatusColor = {
  "Paid": "success",
  "Hold": "dark"
}
function InvestmentList() {
  const dispatch = useDispatch();
  const [openModel, setOpenModel] = useState(false)
  const [filterMonth, setFilterMonth] = useState('')
  const [investmentList, setInvestmentList] = useState([])
  const [meta, setMeta] = useState([])
  const [statusoptions, setStatusOption] = useState([])
  const [status, setStatus] = useState("")

  const [searchParams, setSearchParams] = useSearchParams();
  const [runQuery, { called, loading, data }] = useLazyQuery(GetInvestments)
  const [page, setPage] = useState(1)

  let variables = {
    page: page,
    "this_month": false,
  }

  const [statusQuery, { loading: statusLoading, error: statusError, data: statusData }] = GetStatusByCategory();
  useEffect(() => {
    if (statusData !== undefined && statusData.getStatusByCategory !== null) {
      let statusOptions = []
      statusData.getStatusByCategory.map((value, _) => {
        statusOptions = [
          ...statusOptions,
          {
            value: value.id,
            label: value.display_name,
          }
        ]
      })

      setStatusOption(statusOptions)
    }
  }, [statusData])

  const handlePaginationClick = (page) => {
    setPage(page)
    runQuery({ variables: { ...variables, page: page } })
  }

  useEffect(() => {
    runQuery({
      variables: {
        ...variables,
        this_month: searchParams.get("this-month") !== null || filterMonth !== "",
      }
    })

    // load the statuses
    statusQuery({
      variables: {
        "category": "INVESTMENT",
      }
    })
  }, [])

  useEffect(() => {
    setInvestmentList(data?.getInvestments?.list?.detailed)
    setMeta(data?.getInvestments?.meta_data)
  }, [data]);

  const toggleModal = () => {
    setOpenModel(!openModel)
  };

  const handleFilter = () => {
    runQuery({
      variables: {
        ...variables,
        this_month: searchParams.get("this-month") !== null || filterMonth !== "",
        status: status
      }
    })
  }

  // On clear clicked
  const handleClear = () => {
    runQuery({
      variables: {
        ...variables
      }
    })
  }
  // onChooseFilterOption
  const onChooseFilterOption = (option) => {
    let vars = {}
    switch (option) {
      case "all":
        vars.this_month = false
        break;
      case "this-month":
        vars.this_month = true
        break;
      default:
        break;
    }

    runQuery({
      variables: {
        ...variables,
        ...vars,
      }
    })
  }

  return (
    <>
      {openModel && <AddInvestmentModel state={openModel} toggle={toggleModal} />}

      <PageTitle title="Investments"></PageTitle>

      {/* Page content */}
      <Container className="mt--7" fluid>


        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Investments</h3>
                  </div>
                  <div className="col text-right">
                    <Filter onChooseFilterOption={onChooseFilterOption}></Filter>

                    <Button
                      className="btn-icon"
                      color="secondary"
                      type="button"
                      size="md"
                      onClick={() => toggleModal()}
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-fat-add" />
                      </span>
                      <span className="btn-inner--text">Add</span>
                    </Button>
                  </div>
                </Row>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <Select options={statusoptions} clearValue={false} onChange={(selected) => {
                        setStatus(selected.value)
                      }} />
                    </FormGroup>
                  </Col>

                  <Col md="3">
                    <FormGroup>
                      <Button
                        className="btn-icon"
                        color="primary"
                        type="button"
                        size="md"
                        outline
                        onClick={handleFilter}
                      >Filter</Button>
                      <Button
                        className="btn-icon"
                        color="primary"
                        type="button"
                        size="md"
                        outline
                        onClick={handleClear}
                      >Clear</Button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Amount</th>
                    <th scope="col">InvestedOn</th>
                    <th scope="col">Via</th>
                    <th scope="col">Status</th>
                    <th scope="col">Notes</th>
                    <th scope="col">Additional Notes</th>
                    <th scope="col">Ref</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    investmentList?.map((resource, index) => (
                      <tr key={index}>
                        <td>
                          {(++index) + ((page - 1) * meta?.limit)}
                        </td>
                        <td>
                          <span className="mb-0 text-sm">
                            <Button color="default" size="md" outline type="button">
                              {resource.amount} INR
                            </Button>
                          </span>
                        </td>
                        <td>
                          {
                            formatDate(resource.investedOn)
                          }
                        </td>
                        <td>
                          <div className="avatar-group">
                            <a
                              className="avatar avatar-sm"
                              href="#pablo"
                              id={`tootip-${index}`}
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                className="rounded-circle"
                                src={require(`../../assets/img/icons/${resource.investedVia.toLowerCase()}.png`)}
                              />
                            </a>
                            <UncontrolledTooltip
                              delay={0}
                              target={`tootip-${index}`}
                            >
                              {resource.investedVia}
                            </UncontrolledTooltip>
                          </div>
                        </td>
                        <td>
                          <Badge color="" className="badge-dot mr-4">
                            <i className={`bg-${StatusColor[resource.status?.display_name]}`} />
                            <span className={`text-${StatusColor[resource.status?.display_name]}`}>
                              {resource.status?.display_name}
                            </span>
                          </Badge>
                        </td>
                        <td>
                          {resource.notes ? resource.notes : "-"}
                        </td>
                        <td>
                          {resource.additionalNotes ? resource.additionalNotes : "-"}
                        </td>
                        <td>
                          {resource.ref ? resource.ref : "-"}
                        </td>
                        <td>
                          <InvestmentTableActions resource={resource}></InvestmentTableActions>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
              <CardFooter>
                <div className="col text-right">
                  <RenderPagination meta={meta} handlePageClick={handlePaginationClick}></RenderPagination>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>


      </Container>
    </>
  );
};

export default InvestmentList;
