const { gql, useMutation } = require("@apollo/client");


const UploadFileGQL = gql`
mutation file_upload($content: String!, $fileName: String!, $id: String!, $referenceInto: String!) {
  uploadFile(input:{
    content: $content
    filename:$fileName
    id:$id,
    reference:$referenceInto
  })
}
`

const UploadFile = () => {
  const [mutateFunction,{ loading, error, data }] = useMutation(UploadFileGQL);
  return [mutateFunction,{ loading, error, data }];
}

export {
  UploadFile
}