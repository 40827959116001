import { Chart } from "chart.js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvestmentChart } from "slices/investment.api";
import { chartOptions } from "variables/charts";
import { parseOptions } from "variables/charts";
import { investmentChart } from "variables/charts";

const { Bar } = require("react-chartjs-2")
const { Card, CardHeader, Row, CardBody } = require("reactstrap")

const InvestmentChart = () => {
  const { investmentChartList } = useSelector((state) => state.api);

  let labels = []
  let data = []
  // eslint-disable-next-line array-callback-return
  investmentChartList?.map((value, index) => {
    labels.push(value.month_name)
    data.push(value.total_investment)
  })
  investmentChart.data.labels = labels
  investmentChart.data.datasets[0].data = data


  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const dispatch = useDispatch();
  console.log("Year---",new Date().getFullYear())
  const [year, setYear] = useState(new Date().getFullYear())

  useEffect(() => {
    if (investmentChartList?.length <= 0) {
      dispatch(fetchInvestmentChart({
        "year":"",
      }));
    }
  }, [dispatch]);
  return (
    <Card className="shadow">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h6 className="text-uppercase text-muted ls-1 mb-1">
              Performance
            </h6>
            <h2 className="mb-0">Investment Graph</h2>
          </div>
          <div>
            <i className="btn fas fa-arrow-left text-default mr-3" onClick={() => {
              let y = year - 1
              setYear(y)
              dispatch(fetchInvestmentChart({
                "year" :  y,
              }));
            }}/>
            {year} &nbsp;
            <i className="btn fas fa-arrow-right text-default mr-3" onClick={() => {
              let y = year + 1
              setYear(y)
              dispatch(fetchInvestmentChart({
                "year" :  y,
              }));
            }}/>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        {/* Chart */}
        <div className="chart">
          <Bar
            data={investmentChart.data}
            options={investmentChart.options}
          />
        </div>
      </CardBody>
    </Card>
  )
}


export default InvestmentChart