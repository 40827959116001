
import { useLazyQuery, useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

const TotalEmiReceiveGQL = gql`
    query get_total_emi_received($thisMonth: Boolean, $upcomingMonth:Boolean, $receiveThisMonth: Boolean) {
        totalEmiReceived(filter:{
            ThisMonth:$thisMonth,
            UpcomingMonth:$upcomingMonth,
            ReceiveThisMonth: $receiveThisMonth
        })
    }
`
const GetEmiPaymentsGQL = gql`
  query payments($customerID: String, $loanID: Int, $thisMonth: Boolean!,$page: Int!, $limit:Int!) {
    payments(filter :{
      loan_id:$loanID
      customer_id:$customerID
      ThisMonth:$thisMonth
    },pagination:{
      limit :$limit
      page:$page
    }) {
      list {
        emi_payment {
          id
          customer_id
          loan_id
          payment_date
          amount_paid
          status
          payment_day
          payment_month
          payment_year
        }
        loan {
          id
          customer_id
          title
          emi_mode
          loan_amount
          monthly_emi
          loan_date
          first_emi_on
          last_emi_on
          emi_mode_value
          total_amount_received
          status
          status_name
        }
        customer {
          id
          customer_id
          name
          contact_details
          phone_number
          notes
        }
      }
      meta_data {
          limit
          currentPage
          currentElements
          totalPages
          totalElements
      }
    }
  }
`
const EMIPaymentsSumGQL = gql`
query emi_payment_sum {
  emiPaymentsSum(by:"month"){
    month
    year
    month_name
    total
  }
}
`

const CheckPaymentGQL = gql`
  query check_payment($action: String!, $loanID: Int!, $date: String!) {
    checkPayment(action:$action, loanID:$loanID, paymentDate:$date) {
      value
      status
    }
  }
`
const GetEmiUpcomingListGQL = gql`
  query upcoming_emi_list($customerID: String, $page: Int!,$limit: Int!) {
    upcomingEmiList(
      filter:{
        customer_id:$customerID
      },
      pagination: {
        limit :$limit
        page:$page
      }
    ) {
      list {
        customer {
          id
          customer_id
          name
          phone_number
          contact_details
          notes
        }
        loan {
          id
          customer_id
          title
          emi_mode
          loan_amount
          monthly_emi
          loan_date
          emi_mode_value
          total_amount_received
          status
        }
        due_date
      }
      meta_data {
        limit
        currentPage
        currentElements
        totalPages
        totalElements
      }
    }
  }
`

const GetTotalEmi = ({ thisMonth, upcomingMonth, receiveThisMonth }) => {
  const { loading, error, data } = useQuery(TotalEmiReceiveGQL, {
    variables: {
      "thisMonth": thisMonth,
      "upcomingMonth": upcomingMonth,
      "receiveThisMonth": receiveThisMonth,
    }
  });
  return { loading, error, data };
}


// GetEmiPayments
const GetEmiPayments = () => {
  const [runQuery, { loading, error, data }] = useLazyQuery(GetEmiPaymentsGQL);
  return [runQuery, { loading, error, data }];
}

// CheckPayment
const CheckPayment = () => {
  const [runQuery, { loading, error, data }] = useLazyQuery(CheckPaymentGQL);
  return [runQuery, { loading, error, data }];
}
// CheckPayment
const EmiPaymentSum = () => {
  const [runQuery, { loading, error, data }] = useLazyQuery(EMIPaymentsSumGQL);
  return [runQuery, { loading, error, data }];
}

// GetEmiUpcomingPayments
const GetEmiUpcomingPayments = () => {
  const [runQuery, { loading, error, data }] = useLazyQuery(GetEmiUpcomingListGQL);
  return [runQuery, { loading, error, data }];
}

export {
  GetTotalEmi,
  GetEmiPayments,
  CheckPayment,
  EmiPaymentSum,
  GetEmiUpcomingPayments
}