import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Media,
  FormGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
  CardBody,
  CardFooter,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';


import { AddLoanModel } from "./add";
import PageTitle from "views/partials/pageTitle";
import LoanTable from "./table";
import RenderPagination from "views/partials/pagination";
import LoanActionTabs from "./tabs";
import { GetLoans } from "components/Appolo/loan.query";
import CustomerSearch from "views/partials/customerSearch";
import Filter from "views/partials/widgets/filter";

function LoanList() {
  const dispatch = useDispatch();
  const [customerID, setCustomerID] = useState()

  const [runQuery, { loading, data, err }] = GetLoans()

  const [loanList, setLoanList] = useState([])
  const [page, setPage] = useState(1)
  const [meta, setMeta] = useState([])

  const queryVariables = {
    "page": page,
    "status": "active",
  }
  // On filter clicked
  const handleFilter = () => {
    runQuery({ variables: { ...queryVariables, "customer_id": customerID } })
  }

  // On clear clicked
  const handleClear = () => {
    runQuery({ variables: { ...queryVariables } })
  }

  const onClick = (page) => {
    setPage(page)
    runQuery({ variables: { ...queryVariables, page: page } })
  }

  useEffect(() => {
    setLoanList(data?.loans?.list)
    setMeta(data?.loans?.meta_data)
  }, [data])

  useEffect(() => {
    runQuery({ variables: { ...queryVariables } })
  }, [dispatch]);

  const runGQLQuery = (variables) => {
    runQuery({
      variables: {
        ...queryVariables,
        ...variables
      }
    })
  }
  const [openModel, setOpenModel] = useState(false)
  const toggleModal = () => {
    setOpenModel(!openModel)
  };

  // onChooseFilterOption
  const onChooseFilterOption = (option) => {
    let variables = {}
    switch (option) {
      case "all":
        variables.this_month = false
        break;
      case "this-month":
        variables.this_month = true
        break;
      default:
        break;
    }

    runGQLQuery(variables)
  }


  return (
    <>
      {openModel && <AddLoanModel state={openModel} toggle={toggleModal} />}

      <PageTitle title="Investments"></PageTitle>

      {/* Page content */}
      <Container className="mt--7" fluid>

        <LoanActionTabs></LoanActionTabs>

        <Row className="">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Active Loans</h3>
                  </div>
                  <div className="col text-right">
                    <Filter onChooseFilterOption={onChooseFilterOption}></Filter>
                    <Button
                      className="btn-icon"
                      color="secondary"
                      type="button"
                      size="md"
                      onClick={() => toggleModal()}
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-fat-add" />
                      </span>
                      <span className="btn-inner--text">Add</span>
                    </Button>
                  </div>
                </Row>

                <Row className="mt-3">
                  <Col md="3">
                    <FormGroup>
                      <CustomerSearch onCustomerSelect={(selected) => {
                        setCustomerID(selected.value)
                      }} />
                    </FormGroup>
                  </Col>

                  <Col md="3">
                    <FormGroup>
                      <Button
                        className="btn-icon"
                        color="primary"
                        type="button"
                        size="md"
                        outline
                        onClick={handleFilter}
                      >Filter</Button>
                      <Button
                        className="btn-icon"
                        color="primary"
                        type="button"
                        size="md"
                        outline
                        onClick={handleClear}
                      >Clear</Button>
                    </FormGroup>
                  </Col>
                </Row>

              </CardHeader>
              {loading ? (
                <div className="text-center">
                  <Spinner
                    size="sm"
                    type="grow"
                    className="text-default"
                  >
                    <span className=" sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                loanList && loanList.length > 0 ? (
                  <LoanTable page={page} meta={meta} loanList={loanList}></LoanTable>
                ) : (
                  <div className="text-center">No data found</div>
                )
              )}
              <CardFooter>
                <div className="col text-right">
                  <RenderPagination meta={meta} handlePageClick={onClick}></RenderPagination>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>


      </Container>
    </>
  );
};

export default LoanList;
