import { SearchCustomers } from "components/Appolo/customer.query";
import { SearchCustomerLoans } from "components/Appolo/loan.query"
import { useState } from "react";
import AsyncSelect from 'react-select/async';


// CustomerSearch
const OncustomerSearch = ({onCustomerSelect}) => {
    const [search, { loading, error, data }] = SearchCustomers()
    const [inputValue, setInputValue] = useState('');
    
    const loadOptions = async (
        inputValue, 
        callback
    ) => {
        try {
            search({variables:{customerName:inputValue}})
            const suggestions = data?.searchCustomers?.map((item) => ({
                value: item.customer.customer_id, 
                label: item.customer.name, 
            }));
            if (suggestions == null) {
                callback([]);
                return
            }
            callback([
                ...suggestions
            ]);
        } catch (error) {
            console.error('Error fetching suggestions:', error);
            callback([]);
        }
    };

    const onChange = (selected) => {
        setInputValue(selected.label)
        onCustomerSelect(selected)
    }
    return (
        <>
        <AsyncSelect
            cacheOptions
            defaultOptions
            loadOptions={loadOptions}
            value={{ label: inputValue }}
            onChange={onChange}
            placeholder="Type to search..."
        />
        </>
    )
}

// 
const SearchCustomerLoan = ({onCustomerSelect}) => {
   
    const [search, { loading, error, data }] = SearchCustomerLoans()
    const [inputValue, setInputValue] = useState('');
   
    const loadOptions = async (
        inputValue, 
        callback
    ) => {
        // try {
            search({variables:{customerName:inputValue}})
            const suggestions = data?.searchCustomerLoans?.map((item) => ({
                value: item.customer.customer_id, 
                label: item.customer.name, 
            }));
            callback([
                ...suggestions
            ]);
        // } catch (error) {
        //     console.error('Error fetching suggestions:', error);
        //     callback([]);
        // }
    };

    const onChange = (selected) => {
        setInputValue(selected.label)
        onCustomerSelect(selected)
    }
    return (
        <>
        <AsyncSelect
            cacheOptions
            defaultOptions
            loadOptions={loadOptions}
            value={{ label: inputValue }}
            onChange={onChange}
            placeholder="Type to search..."
        />
        </>
    )
}
const CustomerSearch = (props) => {
   
    

    if (props.onCustomer === true) {
        return (
            <OncustomerSearch onCustomerSelect={props.onCustomerSelect}></OncustomerSearch>
        )
    } 
    return <SearchCustomerLoan onCustomerSelect={props.onCustomerSelect}></SearchCustomerLoan>

}

export default CustomerSearch