
import {
  Card,
  CardHeader,
  Row,
  Col,
  NavItem,
  Nav,
  NavLink
} from "reactstrap";
import classnames from "classnames";

const LoanActionTabs = () => {
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const lastUrlPart = urlParts.pop();


  const navItems = [
    {
      isActive: (lastUrlPart === "active" || lastUrlPart === ""),
      url: "/admin/loans/active",
      title: "Active"
    },{
      isActive: lastUrlPart === "closed",
      url: "/admin/loans/closed",
      title: "Closed"
    },{
      isActive: lastUrlPart === "cancelled",
      url: "/admin/loans/cancelled",
      title: "Cancelled"
    }
  ]
  return (
    <>
      <Row className="mt-5">
        <Col className="-5" xl="12">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Nav
                className="nav-fill flex-column flex-sm-row"
                id="tabs-text"
                pills
                role="tablist"
              >

                {
                  navItems.map((resource, index) => (
                    <NavItem key={index}>
                      <NavLink
                        className={classnames("mb-sm-3 mb-md-0", {
                          active: resource.isActive
                        })}

                        href={resource.url}
                        role="tab"
                      >
                        {resource.title}
                      </NavLink>
                    </NavItem>
                  ))
                }


              </Nav>
            </CardHeader>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default LoanActionTabs;
