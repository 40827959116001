import {
  Button,
  Input,
  FormGroup,
  Form,
  Modal,
  Card,
  CardBody,
  InputGroupAddon,
  InputGroupText,
  InputGroup,


} from "reactstrap";
import React, { useEffect, useState } from 'react';
import ReactDatetime from "react-datetime";
import Select from 'react-select'

import { useDispatch } from "react-redux";
import { nameValidator } from "helpers/nameValidator";

import { FormateDate } from "utils/helpers";

import ListAgentFormWidget from "views/partials/widgets/list-agents";
import { AddAgentTransaction } from "../../components/Appolo/agent.query";

const statusOptions = [
  {
    value: "CREDIT",
    label: "CREDIT"
  }, {
    value: "DEBIT",
    label: "DEBIT"
  }
]
const AddAgentTransactionModel = (props) => {
  const { state, toggle } = props;


  const [amount, setAmount] = useState({ value: '', error: '' });
  const [agent, setAgent] = useState({ value: '', error: '', label: '' });
  const [category, setCategory] = useState({ value: '', error: '', label: '' });
  const [notes, setNotes] = useState({ value: '', error: '', label: '' });
  const [status, setStatus] = useState({ value: '', error: '', label: '' });
  const [date, setDate] = useState({ value: '', error: '', label: '' });
  const [success, setSuccess] = useState(false);

  const [addAgentTrans, { loading, err, data }] = AddAgentTransaction()


  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    const validationVariables = [
      { name: "amount", state: amount, error: nameValidator(amount.value), setState: setAmount },
      { name: "agent", state: agent, error: nameValidator(agent.value), setState: setAgent },
      { name: "category", state: category, error: nameValidator(category.value), setState: setCategory },
      { name: "status", state: status, error: nameValidator(status.value), setState: setStatus },
      { name: "date", state: date, error: nameValidator(date.value), setState: setDate },
    ];

    let success = true
    for (const { state, error, setState, name } of validationVariables) {
      if (error) {
        console.log("name::",name)
        console.log("error::",error)
        setState({ ...state, error });
        success = false
      }
    }
    console.log(success)
    if (!success) {
      return
    }

    const formattedDate = FormateDate(date.value);


    addAgentTrans({
      variables: {
        agent_id: agent.value,
        category: category.value,
        amount: amount.value,
        status: status.value,
        notes: notes.value,
        date: formattedDate,
      }
    })


  };
  useEffect(() => {
    if (data?.addAgentTransaction) {
      setSuccess(true)
      setTimeout(() => {
        window.location.reload();
      }, 3000)
    }   
  }, [data])


  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={state}
      toggle={toggle}
      size="lg"
    >
      <div className="modal-header">
        <h2 className="modal-title" id="exampleModalLabel">
          Add Agent Transaction
        </h2>
      </div>
      <div className="modal-body">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="p-0">
            <Form>

              {

                <ListAgentFormWidget error={agent.error} onChange={(selected) => {
                  setAgent({ value: selected.value, error: '' })
                }} >
                </ListAgentFormWidget>
              }

              <FormGroup>
                <Select options={statusOptions} error={status.error} clearValue={false} onChange={(selected) => {
                  setStatus({ value: selected.value, error: '' })
                }} />
              </FormGroup>

              <FormGroup className={category.error ? 'has-danger' : ''}>
                <Input
                  className="form-control-alternative"
                  id="input-category"
                  placeholder="Category"
                  type="text"
                  onChange={(event) => {
                    setCategory({ value: event.target.value, error: '' })
                  }}
                />
                {
                  category.error && (
                    <label className="text-danger p-1">{category.error}</label>
                  )
                }
              </FormGroup>

              <FormGroup className={amount.error ? 'has-danger' : ''}>
                <Input
                  className="form-control-alternative"
                  id="input-amount"
                  placeholder="Amount"
                  type="text"
                  onChange={(event) => {
                    setAmount({ value: event.target.value, error: '' })
                  }}
                />
                {
                  amount.error && (
                    <label className="text-danger p-1">{amount.error}</label>
                  )
                }
              </FormGroup>

              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime

                    inputProps={{
                      placeholder: "Date Picker Here"
                    }}
                    timeFormat={false}
                    onChange={(date) => {
                      setDate({ value: date, error: '' })
                    }}
                    closeOnClickOutside={true}
                    closeOnSelect={true}
                  />
                </InputGroup>
                {
                  date.error && (
                    <label className="text-danger p-1">{date.error}</label>
                  )
                }
              </FormGroup>

              {
                err && (
                  <label className="text-danger p-1">{err}</label>
                )
              }
              {
                success && (
                  <label className="text-success p-1">successfully added the transaction</label>
                )
              }
              <div className="text-center">
                <Button color="default" type="button" onClick={handleSubmit}>
                  Add
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
}

export {
  AddAgentTransactionModel
};
