
import Index from "views/Index.js";
import CustomerList from "views/customer/list";

// import AddResource from "views/form/addResource.js";
// import AssignResourceProject from "views/form/assignResources.js";
import Profile from "views/examples/Profile.js";
import Register from "views/examples/Register.js";
import Login from "views/Login";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";
import PaymentsList from "views/payments/List";
import LoanList from "views/loan/list";
import LoanDetails from "views/loan/detail";
// import ProfitCalculator from "views/profit/calculator";
import ProfitCalculator from "views/profit/calculator-v2";
import EmiDueList from "views/emi/dueList";
import EmiPendingList from "views/emi/pending";
import InvestmentList from "views/investment/list";
import CustomerDetails from "views/customer/detail";
import SalesList from "views/sales/list";
import ClosedLoanList from "views/loan/closed-list";
import CancelledLoanList from "views/loan/cancelled-list";
import EmiUpcomingList from "views/emi/upcoming";
import AgentHoldingPaymentsList from "views/agent/agent-holding-payment-list";
import AgentHoldingsSum from "views/agent/agent-sum-list";
import LoanDetailsPDF from "./views/loan/detail-pdf";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <Index />,
    layout: "/admin",
    menu: true
  },
  {
    path: "/customers",
    name: "Customers",
    icon: "ni ni-circle-08 text-pink",
    component: <CustomerList />,
    layout: "/admin",
    menu: true
  },
  {
    path: "/customer/details/:customerid",
    name: "Customer Details",
    icon: "ni ni-circle-08 text-pink",
    component: <CustomerDetails />,
    layout: "/admin",
    menu: false
  },
  {
    path: "/investments",
    name: "Investments",
    icon: "ni ni-money-coins",
    component: <InvestmentList />,
    layout: "/admin",
    menu: true
  },
  {
    path: "/loans/active",
    name: "Loans",
    icon: "ni ni-collection",
    component: <LoanList />,
    layout: "/admin",
    menu: true
  },
  {
    path: "/loans/closed",
    name: "Closed Loans",
    icon: "ni ni-collection",
    component: <ClosedLoanList />,
    layout: "/admin",
    menu: false
  },
  {
    path: "/loans/cancelled",
    name: "Cancelled Loans",
    icon: "ni ni-collection",
    component: <CancelledLoanList />,
    layout: "/admin",
    menu: false
  },
  {
    path: "/emi/due",
    name: "Due List",
    icon: "ni ni-collection",
    component: <EmiDueList />,
    layout: "/admin",
    menu: false
  },
  {
    path: "/emi/pending",
    name: "Pending List",
    icon: "ni ni-collection",
    component: <EmiPendingList />,
    layout: "/admin",
    menu: false
  },
  {
    path: "/emi/upcoming",
    name: "Upcoming List",
    icon: "ni ni-collection",
    component: <EmiUpcomingList />,
    layout: "/admin",
    menu: false
  },
  {
    path: "/loans/detail/:loanid",
    name: "Loan Details",
    icon: "ni ni-single-02 text-yellow",
    component: <LoanDetails />,
    layout: "/admin",
  },

  {
    path: "/loans/detail/:loanid/pdf",
    name: "Loan Details PDF",
    icon: "ni ni-single-02 text-yellow",
    component: <LoanDetailsPDF />,
    layout: "/admin",
  },
  {
    path: "/payments",
    name: "Payments",
    icon: "ni ni-briefcase-24",
    component: <PaymentsList />,
    layout: "/admin",
    menu: true
  },
  {
    path: "/agent/holding-payments",
    name: "Agents",
    icon: "ni ni-satisfied",
    layout: "/admin",
    component: <AgentHoldingsSum />,
    menu: true,
    childs : [{
      path: "/agent/holding-payments",
      name: "Holding Payments",
      icon: "",
      component: <AgentHoldingsSum />,
      layout: "/admin",
    }]
  },
  {
    path: "/agent/holding-payments-list/:agentID",
    name: "Agents",
    icon: "ni ni-collection",
    component: <AgentHoldingPaymentsList />,
    layout: "/admin",
    menu: false
  },
  {
    path: "/sales",
    name: "Sales",
    icon: "ni ni-collection",
    component: <SalesList />,
    layout: "/admin",
    menu: false
  },
  {
    path: "/profit-calculator",
    name: "Profit Calculator",
    icon: "ni ni-collection",
    component: <ProfitCalculator />,
    layout: "/admin",
    menu: true
  },
  // {
  //   path: "/resources/add",
  //   name: "Add Resource",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: <AddResource />,
  //   layout: "/admin",
  // },

  // {
  //   path: "/resources/assign",
  //   name: "Assign Resource",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: <AssignResourceProject />,
  //   layout: "/admin",
  // },


  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: <Icons />,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile />,
    layout: "/admin",
    menu: true
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: <Tables />,
    layout: "/admin",
    menu: false
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
    menu: false,
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: <Register />,
    layout: "/auth",
    menu: false
  },
];
export default routes;
