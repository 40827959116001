
import { 
  Card ,
  CardHeader,
  CardBody,
  Row,
  Nav,
  NavItem,
  NavLink
} from "reactstrap"
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { Line} from "react-chartjs-2";
import classnames from "classnames";
import { 
  chartOptions,
  SalesChart, 
  parseOptions,
} from "variables/charts";
import Chart from "chart.js";

import { Sales } from "components/Appolo/sales.query";

const SalesChartItem = ({whileGetData}) => {
    const [filter, setFilter] = useState("month")

    const { loading, error, data } = useQuery(Sales, {
      variables : {
        "month":filter === "month",
        "year":filter === "year",
      }
    });
    if (window.Chart) {
        parseOptions(Chart, chartOptions());
    }
    
    let labels = []
    let chart_data = []

    !loading && data!== undefined && data["get_sales"].map((value, index) => {
      labels.push(value.name)
      chart_data.push(value.amount)
    })

    // set the data back to callback function
    if (whileGetData !== undefined) {
      whileGetData(data)
    }

    SalesChart.data = (canvas) => {
      return {
        labels: labels,
        datasets: [
          {
            label: "Performance",
            data: chart_data,
          },
        ],
      };
    }
    const SalesChartData = (canvas) => {
      return {
        labels: labels,
        datasets: [
          {
            label: "Performance",
            data: chart_data,
          },
        ],
      };
    }

    return (
        <Card className="bg-gradient-default shadow">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
                <div className="col">
                <h6 className="text-uppercase text-light ls-1 mb-1">
                    Overview
                </h6>
                <h2 className="text-white mb-0">Sales</h2>
                </div>
                <div className="col">
                <Nav className="justify-content-end" pills>
                    <NavItem>
                    <NavLink
                        className={classnames("py-2 px-3", {
                        active: filter === "month",
                        })}
                        href="#"
                        onClick={(e) => setFilter("month")}
                    >
                        <span className="d-none d-md-block">Month</span>
                        <span className="d-md-none">M</span>
                    </NavLink>
                    </NavItem>
                    <NavItem>
                    <NavLink
                        className={classnames("py-2 px-3", {
                        active: filter === "year",
                        })}
                        data-toggle="tab"
                        href="#"
                        onClick={(e) => setFilter("year")}
                    >
                        <span className="d-none d-md-block">Year</span>
                        <span className="d-md-none">W</span>
                    </NavLink>
                    </NavItem>
                </Nav>
                </div>
            </Row>
            </CardHeader>
            <CardBody className="border-0">
                <div className="chart">
                    <Line
                        data={SalesChartData}
                        options={SalesChart.options}
                        getDatasetAtEvent={(e) => console.log(e)}
                    />
                </div>
            </CardBody>
        </Card>
    )
}

export default SalesChartItem