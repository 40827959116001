import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  CardFooter,
  CardBody,
  Badge,
} from "reactstrap";

import React, { useEffect, useState } from 'react';
import { useParams, } from "react-router-dom";

import PageTitle from "views/partials/pageTitle";
import { ListAgentHoldingTransactions } from "components/Appolo/agent.query";
import RenderPagination from "views/partials/pagination";
import { formatDate } from "utils/helpers";


function AgentHoldingPaymentsList() {
  const { agentID } = useParams();

  const [list, setList] = useState([])
  const [meta, setMeta] = useState([])
  const [pageLimit, setPageLimit] = useState(0)

  const [runQuery, { called, loading, data }] = ListAgentHoldingTransactions()
  const [page, setPage] = useState(1)

  let variables = {
    page: page,
    agent_id: agentID,
    limit: 0
  }

  const handlePaginationClick = (page) => {
    setPage(page)
    runQuery({ variables: { ...variables, page: page } })
  }

  useEffect(() => {
    runQuery({
      variables: {
        ...variables,
      }
    })
  }, [])

  useEffect(() => {
    setList(data?.listHoldingAmounts?.list)
    setMeta(data?.listHoldingAmounts?.meta_data)
  }, [data]);


  const handleFilter = () => {
    runQuery({
      variables: {
        ...variables,
        agent_id: agentID
      }
    })
  }

  // On clear clicked
  const handleClear = () => {
    // runQuery({
    //   variables: {
    //     ...variables
    //   }
    // })
  }
  // onChooseFilterOption
  const onChooseFilterOption = (option) => {
    // let vars = {}
    // switch (option) {
    //   case "all":
    //     vars.this_month = false
    //     break;
    //   case "this-month":
    //     vars.this_month = true
    //     break;
    //   default:
    //     break;
    // }

    // runQuery({
    //   variables: {
    //     ...variables,
    //     ...vars,
    //   }
    // })
  }

  const getRandomColor = () => {
    const availableColors = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark'];
    const randomIndex = Math.floor(Math.random() * availableColors.length);
    return availableColors[randomIndex];
  };
  const handleLimitSelection = (count) => {
    runQuery({ variables: { ...variables,limit:count} })
    setPageLimit(count)
  }


  return (
    <>
      <PageTitle title="Agent Holdings List"></PageTitle>

      {/* Page content */}
      <Container className="mt--7" fluid>


        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Agent Payment Transactions</h3>
                  </div>
                  <div className="col text-right">
                  </div>
                </Row>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <Button
                        className="btn-icon"
                        color="primary"
                        type="button"
                        size="md"
                        outline
                        onClick={handleFilter}
                      >Filter</Button>
                      <Button
                        className="btn-icon"
                        color="primary"
                        type="button"
                        size="md"
                        outline
                        onClick={handleClear}
                      >Clear</Button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>

                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">AgentID</th>
                      <th scope="col">Category</th>
                      <th scope="col">Customer Name</th>
                      <th scope="col">Real Amount</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Notes</th>
                      <th scope="col">Transaction Date</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      list?.map((listdata, index) => (
                        <tr key={index}>
                          <td>
                            {(++index) + ((page - 1) * meta?.limit)}
                          </td>
                          <td>
                            <Button color="default" size="sm" outline type="button">
                              {listdata.agent_name}
                            </Button>
                            <Badge className="badge-default" href="#pablo" onClick={e => e.preventDefault()}>
                              {listdata.agent_id}
                            </Badge>
                          </td>
                          <td>

                            <Badge color="primary" pill>
                              {listdata.category}
                            </Badge>
                          </td>
                          <td>
                            {listdata.customer_name}
                          </td>
                          <td>
                            <span className="mb-0 text-sm">
                              <Button color="default" size="sm" outline type="button">
                                <i className="fa fa-inr" />  {listdata.real_amount}
                              </Button>
                            </span>
                          </td>
                          <td>
                            <span className="mb-0 text-sm">
                              {
                                listdata.amount > 0 ? (
                                  <i className="fas fa-arrow-up text-success mr-3" />
                                ) : (
                                  <i className="fas fa-arrow-down text-danger mr-3" />
                                )
                              }
                              {Math.abs(listdata.amount)}
                            </span>
                          </td>
                          <td>
                            {listdata.notes}
                          </td>
                          <td>
                            {
                              formatDate(listdata.transaction_date)
                            }
                          </td>
                          <td>-</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter>
                <div className="col text-right">
                  <RenderPagination
                    meta={meta}
                    handlePageClick={handlePaginationClick}
                    onLimitSelected={handleLimitSelection}
                  ></RenderPagination>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>


      </Container>
    </>
  );
};

export default AgentHoldingPaymentsList;
