export default function authHeader() {
    const token = localStorage.getItem("token");
  
    // if (user && user.access_token) {
      // For Spring Boot back-end
      return {
        // Authorization: "Bearer " + user.access_token,
        // Authorization: "Bearer "+token,
        // 'Content-Type': 'application/json',
      };
  
  }
  