const { gql, useQuery, useLazyQuery } = require("@apollo/client");


const GetStatusByCategoryGQL = gql`
    query get_status_by_catgeory($category: String!) {
        getStatusByCategory(category:$category) {
            id
            display_name
            display_value
            category_name
        }
    }
`

const GetStatusByCategory = () => {
    const [runQuery,{ loading, error, data }] = useLazyQuery(GetStatusByCategoryGQL);
    return [runQuery,{ loading, error, data }];
}
export {
    GetStatusByCategory
}