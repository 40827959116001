
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import React, { useEffect, useState } from 'react';
import PageTitle from "views/partials/pageTitle";
import SalesChartItem from "./chart";
import ProfitCalculator from "views/profit/calculator-v2";
import DataList from "views/profit/data-list";
import { useDispatch, useSelector } from "react-redux";
import { fetchMonthlyTurnover } from "slices/reports";

function SalesList() {
  const [data, setData] = useState()
  const { reports } = useSelector((state) => state.api);
  const dispatch = useDispatch();
console.log(data)
  useEffect(() => {
    dispatch(fetchMonthlyTurnover({
      Latest: true,
    }))
  }, [dispatch])
  return (
    <>
      <PageTitle title="Payments"></PageTitle>
      {/* Page content */}
      <Container className="mt--7" fluid>

        <Row className="">
          <Col className="mb-5" xl="12">
            <SalesChartItem whileGetData={setData}></SalesChartItem>
          </Col>
          <Col className="" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">

              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Amount</th>
                    <th scope="col">No.Of Loans</th>
                    <th scope="col">Month/Year</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data !== undefined && data["get_sales"] !== undefined && data["get_sales"].map((resource, index) => (
                      <tr key={index}>
                        <td>{++index}</td>

                        <td>
                          <span className="text-danger">
                            <i className="fas fa-arrow-down text-warning mr-3" />{" "}
                            <i className="fa fa-inr" /> {resource.amount}
                          </span>
                        </td>
                        <td>
                          {resource.count}
                        </td>
                        <td>
                          {resource.name}
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </Card>
          </Col>
          <Col className="pt-2" xl="12">
            <DataList reports={reports}></DataList>
          </Col>
        </Row>


      </Container>
    </>
  );
};

export default SalesList;
