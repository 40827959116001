// reactstrap components
import { Card, CardBody, Container, Row, Col, CardHeader, List, Spinner, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { fetchMonthlyTurnover } from "slices/reports";
import { IsEmptyObject } from "utils/helpers";
import { GetCustomerCount } from "components/Appolo/customer.query";
import { GetSalesTotal } from "components/Appolo/sales.query";
import { GetActiveLoansCount } from "components/Appolo/loan.query";
import { GetTotalInvestments } from "components/Appolo/investment.query";
import { GetTotalEmi } from "components/Appolo/emi.query";
import { GetMode } from "components/Appolo/common.query";
import { GetStatusByCategory } from "components/Appolo/status.query";

const Header = () => {
  const dispatch = useDispatch();

  const { emiDueCount, reports } = useSelector((state) => state.api);

  const [totalInvestment, setTotalInvestment] = useState(0)
  const [totalProfit, setTotalProfit] = useState(0)

  const { loading: customerCountLoader, data: customerCountData } = GetCustomerCount();
  const { loading: salesLoader, data: salesTotalData } = GetSalesTotal();
  const { loading: activeLoansLoader, data: activeLoansData } = GetActiveLoansCount();
  const [runTotalInvest, { loading: totalInvestmentLoader, data: totalInvestmentData }] = GetTotalInvestments();
  const { loading: totalEmiLoader, data: totalEmiData } = GetTotalEmi({ "thisMonth": false, "upcomingMonth": false });
  const { loading: totalEmiThisLoader, data: totalEmiThisData } = GetTotalEmi({ "thisMonth": true, "upcomingMonth": false });
  const { loading: totalEmiUpLoader, data: totalEmiUpData } = GetTotalEmi({ "thisMonth": false, "upcomingMonth": true });
  const { loading: totalUptoThisMonthEmiUpLoader, data: totalUptoThisMonthEmiUpData } = GetTotalEmi({ "thisMonth": false, "upcomingMonth": false, "receiveThisMonth": true });

  // get status by category name
  const [statusQuery, { loading: statusLoading, error: statusError, data: statusData }] = GetStatusByCategory()
  const [investmentStatuses, setInvestmentStatuses] = useState([])
  const [selectedStatus, setSelectedStatus] = useState()
  const [thisMonthInvest, setThisMonthInvest] = useState(0)
  const [allInvest, setAllInvest] = useState()

  useEffect(() => {
    runTotalInvest({
      variables: {
        "thisMonth": false,
      }
    }).then(result => {
      setAllInvest(result?.data?.totalInvestments)
    })
    runTotalInvest({
      variables: {
        "thisMonth": true,
      }
    }).then(result => {
      setThisMonthInvest(result?.data?.totalInvestments)
    })
  }, [dispatch])
  useEffect(() => {
    if (statusData !== undefined && statusData.getStatusByCategory !== null) {
      setInvestmentStatuses(statusData.getStatusByCategory)
    }
  }, [statusData])

  // changable function
  const investmentChangeHTML = () => {
    return (
      <UncontrolledDropdown group>
        <DropdownToggle
          caret
          color="none"
          size="sm"
        />
        <DropdownMenu>
          {
            investmentStatuses?.map((val, index) => (
              <DropdownItem className={selectedStatus === val.id ? "active" : ""} onClick={() => {
                setSelectedStatus(val.id)
                runTotalInvest({
                  variables: {
                    "thisMonth": true,
                    "status": val.id,
                  }
                }).then(result => {
                  setThisMonthInvest(result?.data?.totalInvestments)
                })
              }}>
                {val.display_name}
              </DropdownItem>
            ))
          }
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  }
  const overAllInvestmentChangeHTML = () => {
    return (
      <UncontrolledDropdown group>
        <DropdownToggle
          caret
          color="none"
          size="sm"
        />
        <DropdownMenu>
          {
            investmentStatuses?.map((val, index) => (
              <DropdownItem className={selectedStatus === val.id ? "active" : ""} onClick={() => {
                setSelectedStatus(val.id)
                runTotalInvest({
                  variables: {
                    "thisMonth": false,
                    "status": val.id,
                  }
                }).then(result => {
                  setAllInvest(result?.data?.totalInvestments)
                })
              }}>
                {val.display_name}
              </DropdownItem>
            ))
          }
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  }
  const dashboardWidgets = [
    {
      title: "General",
      items: [{
        "title": "Customers",
        "sub_title": "Total customers count",
        "loading": customerCountLoader,
        "value": customerCountData?.getCustomersCount,
        "link": "/admin/customers",
        "icon": "fa fa-user",
        "icon_bg": "bg-gradient-primary",
        "text_color": "text-primary"
      }, {
        "title": "sales",
        "sub_title": "Total sales",
        "loading": salesLoader,
        "value": salesTotalData?.getTotalSales,
        "amount": true,
        "link": "/admin/sales",
        "icon": "fa fa-bar-chart ",
        "icon_bg": "bg-gradient-success",
        "text_color": "text-success"

      }, {
        "title": "Active Loans",
        "sub_title": "Total no of active loans",
        "loading": activeLoansLoader,
        "value": activeLoansData?.getActiveLoansCount,
        "amount": false,
        "link": "/admin/loans/active",
        "icon": "ni ni-credit-card ",
        "icon_bg": "bg-gradient-info",
        "text_color": "text-info"
      }]
    },
    //  
    // Investment
    // 
    {
      title: "Investment",
      items: [{
        "title": "Total Investment",
        "sub_title": "Total invested amount",
        "loading": totalInvestmentLoader,
        "value": allInvest,
        "amount": true,
        "link": "/admin/investments",
        "icon": "ni ni-money-coins",
        "icon_bg": "bg-gradient-primary",
        "text_color": "text-primary",
        "changable": true,
        "changable_html": overAllInvestmentChangeHTML
      }, {
        "title": "This Month",
        "sub_title": "Total invested amount on this month",
        // "loader": totalInvestmentThisMonthLoader,
        "value": thisMonthInvest,
        "amount": true,
        "link": "/admin/investments?this-month=true",
        "icon": "ni ni-money-coins",
        "icon_bg": "bg-gradient-dark",
        "text_color": "",
        "changable": true,
        "changable_html": investmentChangeHTML
      }, {
        "title": "Actual Investment",
        "sub_title": "Calculated actual invested amount",
        "value": totalInvestment,
        "amount": true,
        "link": "/admin/profit-calculator",
        "icon": "ni ni-align-center",
        "icon_bg": "bg-gradient-info",
        "text_color": "text-info"
      }, {
        "title": "Profit",
        "sub_title": "Calculated profit",
        "value": totalProfit,
        "amount": true,
        "link": "/admin/profit-calculator",
        "icon": "ni ni-chart-bar-32",
        "icon_bg": "bg-gradient-success",
        "text_color": "text-success"
      }]
    },
    // 
    // EMI
    // 
    {
      title: "EMI / Payment",
      items: [{
        "title": "Total EMI received",
        "sub_title": "Total emi received",
        "loader": totalEmiLoader,
        "value": totalEmiData?.totalEmiReceived,
        "amount": true,
        "link": "",
        "icon": "ni ni-credit-card",
        "icon_bg": "bg-gradient-primary",
        "text_color": "text-primary"
      }, {
        "title": "Received This Month",
        "sub_title": "Amount Received on this month",
        "loader": totalEmiThisLoader,
        "value": totalEmiThisData?.totalEmiReceived,
        "amount": true,
        "link": "/admin/payments",
        "icon": "ni ni-credit-card",
        "icon_bg": "bg-gradient-info",
        "text_color": "text-info"
      }, {
        "title": "Receive On this Month",
        "sub_title": "Amount should receive for this month",
        "loader": totalUptoThisMonthEmiUpLoader,
        "value": totalUptoThisMonthEmiUpData?.totalEmiReceived,
        "amount": true,
        "link": "",
        "icon": "ni ni-credit-card",
        "icon_bg": "bg-gradient-grey"
      }, {
        "title": "Upcoming Month",
        "sub_title": "Amount should receive next month",
        "loader": totalEmiUpLoader,
        "value": totalEmiUpData?.totalEmiReceived,
        "amount": true,
        "link": "/admin/emi/upcoming",
        "icon": "ni ni-credit-card",
        "icon_bg": "bg-gradient-dark"
      }, {
        "if": emiDueCount !== undefined && emiDueCount,
        "title": "Dues",
        "sub_title": "Total emi due for this month",
        "value": emiDueCount,
        "amount": false,
        "link": "/admin/emi/due",
        "icon": "ni ni-button-pause",
        "icon_bg": "bg-gradient-danger",
        "text_color": "text-danger"
      }]
    }
  ]


  useEffect(() => {
    dispatch(fetchMonthlyTurnover({
      Latest: true,
    }))

    statusQuery({
      variables: {
        "category": "INVESTMENT",
      }
    })
  }, [dispatch]);

  useEffect(() => {
    if (reports !== undefined) {
      let totalProfit = 0
      let totalInvestment = 0

      if (!IsEmptyObject(reports.monthlyTurnover)) {
        reports.monthlyTurnover.forEach((resource) => {
          let amount = resource.investment_emi_difference;
          if (amount > 0) {
            totalProfit += amount;
          } else {
            totalInvestment -= amount; // Change (amount * -1) to -amount
          }
        });
      }


      setTotalInvestment(totalInvestment)
      setTotalProfit(totalProfit)
    }
  }, [dispatch, reports]);
  return (
    <>
      <div
        className="header pb-8 pt-5 pt-md-8"
        style={{
          minHeight: "300px",
          backgroundImage:
            "url(" + require("../../assets/img/theme/background.jpg") + ")",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}

      >
        <span className="mask bg-gradient-default opacity-8 text-center" />

        <Container fluid>
          <div className="header-body">

            {/* Card stats */}
            {/* <Row className="py-2">
              <Col xl="3" md="3">
                  <Card className="bg-gradient-primary border-0">
                    <CardBody>
                      <div class="row">
                            <div class="col">
                                <h5 class="card-title text-uppercase text-muted mb-0 text-white">Actual Investment</h5>
                                <span class="h2 font-weight-bold mb-0 text-white">{totalInvestment}</span>
                                <div class="progress progress-xs mt-3 mb-0"><div class="progress-bar bg-success" role="progressbar" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div></div>
                            </div>
                        </div>
                    </CardBody>
                  </Card>
              </Col>
            </Row> */}

            <Row>


              {
                dashboardWidgets.map((resource, sl) => (
                  <>
                    <Col xl="4">
                      <Card className="shadow h-100">
                        <CardHeader className="card-header">
                          <div className="align-items-center">
                            <h5 className="h3 mb-0 font-weight-bold">{resource.title.toUpperCase()}</h5>
                          </div>
                        </CardHeader>
                        <CardBody>
                          <List className="list-group list-group-flush list my--3">
                            {
                              resource.items.map((data, key) => (
                                <>
                                  {(data.if === undefined || data.if === true) && (
                                    <li className="list-group-item px-0">
                                      <Row className="align-items-center">
                                        <div className="col-auto">
                                          <span className={`avatar rounded-circle ${data.icon_bg}`}>
                                            <i className={data.icon} />
                                            {/* <img
                                            alt="..."
                                            className="rounded-circle"
                                            src={require(`../../assets/img/icons/dashboard/${data.icon.toLowerCase()}`)}
                                          /> */}
                                          </span>
                                        </div>
                                        <div className="col ml--2">
                                          <h4 className="text-uppercase text-darker mb-0"><span className={data.text_color}>
                                            {data.title}
                                            {
                                              data.changable ? (
                                                data.changable_html()

                                              ) : ""
                                            }
                                          </span></h4>
                                          <span className="text-nowrap text-muted">{data.sub_title}</span>
                                        </div>
                                        <Col className="col-auto">
                                          {data.loading ? (
                                            <Spinner className="text-default" key={`key-${sl}`}>
                                              <span className=" sr-only">Loading...</span>
                                            </Spinner>
                                          ) : (
                                            <>
                                              <span className={`h3 font-weight-bold mb-0 ${data.text_color_}`}>
                                                {data.amount && (
                                                  <i className="fa fa-inr" />
                                                )}
                                                &nbsp;
                                                {data.value}
                                              </span>
                                              {
                                                data.link !== "" && (
                                                  <p className="mt-0 mb-0 text-muted text-sm">
                                                    <a href={data.link} className="btn-md float-right" > View</a>
                                                  </p>
                                                )
                                              }
                                            </>
                                          )}

                                        </Col>
                                      </Row>
                                    </li>
                                  )}
                                </>
                              ))
                            }
                          </List>
                        </CardBody>
                      </Card>
                    </Col>
                  </>
                ))
              }

              {/*  Investment */}


              {/* EMI */}

            </Row>

          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
