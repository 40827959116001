import { configureStore,serializableCheck, getDefaultMiddleware } from '@reduxjs/toolkit'

import apiReducer from "./slices/api";
import messageReducer from "./slices/message";

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: {
      // Ignore the `headers` property in Axios actions
      ignoredPaths: ['']
    }
  })
];


const reducer = {
  api: apiReducer,
  message: messageReducer
}

const store = configureStore({
  reducer: reducer,
  devTools: true,
  middleware
})

export default store;
