import {
  Button,
  Input,
  FormGroup,
  Form,
  Modal,
  Card,
  CardBody,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,


} from "reactstrap";
import React, { useEffect, useState } from 'react';
import ReactDatetime from "react-datetime";
import Select from 'react-select'

import { useDispatch, useSelector } from "react-redux";
import { setMessage } from 'slices/message';
import { resetApiError } from "slices/api";
import { nameValidator, emptyValidator } from "helpers/nameValidator";

import { fetchCustomers, fetchCustomerDetails } from "slices/customer.api";
import { addLoan } from "slices/loan.api";
import CustomerSearch from "views/partials/customerSearch";

const options = [
  { value: 'monthly', label: 'Monthly' },
]


const AddLoanModel = (props) => {
  const { state, toggle } = props;


  const [customerOptions, setCustomerOptions] = useState([]);

  const [customerID, setCustomerID] = useState({ value: '', error: '' });
  const [loanAmount, setLoanAmount] = useState({ value: '', error: '' });
  const [date, setDate] = useState({ value: '', error: '' });
  const [title, setTitle] = useState({ value: '', error: '' });
  const [emiAmount, setEmiAmount] = useState({ value: '', error: '' });
  const [tenor, setTenor] = useState({ value: '', error: '' });
  const [emiMode, setEmiMode] = useState({ value: '', error: '' });

  const { apiError, addedNewLoan, customersList, customerDetails } = useSelector((state) => state.api);
  const dispatch = useDispatch();
  // check there is an api error
  if (apiError !== undefined && apiError !== "") {
    dispatch(setMessage({
      "type": "error",
      "message": apiError
    }));
    dispatch(resetApiError());

  }
  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    const titleError = nameValidator(title.value)
    const emiAmountError = nameValidator(title.value)
    const customerError = nameValidator(customerID.value)
    const loanError = nameValidator(loanAmount.value)
    const dateError = emptyValidator(date.value)
    const tenorError = emptyValidator(tenor.value)
    const emiModeError = emptyValidator(tenor.value)

    if (customerError || loanError || dateError || titleError || emiAmountError || tenorError || emiModeError) {
      setCustomerID({ ...customerID, error: customerError })
      setLoanAmount({ ...loanAmount, error: loanError })
      setDate({ ...date, error: dateError })
      setTitle({ ...title, error: titleError })
      setEmiAmount({ ...emiAmount, error: emiAmountError })
      setTenor({ ...tenor, error: tenorError })
      setEmiMode({ ...emiMode, error: emiModeError })
      return
    }
    const fdate = new Date(date.value);
    const formattedDate = fdate.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric"
    });

    // add loan
    const requestPayload = {
      "title": title.value,
      "emi_mode": emiMode.value,
      "customer_id": customerID.value,
      "loan_amount": parseInt(loanAmount.value),
      "emi_mode_value": parseInt(tenor.value),
      "monthly_emi": parseInt(emiAmount.value),
      "loan_date": formattedDate
    }
    dispatch(addLoan(requestPayload))

  };

  // onSelectCustomer
  const onSelectCustomer = (selected) => {
    setCustomerID({ value: selected.value, error: '' })
    dispatch(fetchCustomerDetails(selected.value))
  };
  console.log("customerDetails::", customerDetails)
  // add new in
  useEffect(() => {
    if (addedNewLoan === true) {
      dispatch(setMessage({
        "type": "success",
        "message": "You have successfully added new payment data"
      }));
      setTimeout(() => {
        window.location.reload();
      }, 1000)
    }
  }, [addedNewLoan, dispatch]);

  // fetch customers
  useEffect(() => {
    dispatch(fetchCustomers());
  }, [dispatch])

  // load the customers
  useEffect(() => {
    const mappedOptions = customersList.map((resource, index) => ({
      value: resource.customer_id,
      label: `${resource.customer_id} - ${resource.name}`,
    }));
    setCustomerOptions(mappedOptions);
  }, [customersList]);


  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={state}
      toggle={toggle}
      size="lg"
    >
      <div className="modal-header">
        <h2 className="modal-title" id="exampleModalLabel">
          Add Loan 12
        </h2>
      </div>
      <div className="modal-body">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="p-0">
            <Form>
              <FormGroup className={title.error ? 'has-danger' : ''}>
                <Input
                  className="form-control-alternative"
                  id="input-title"
                  placeholder="Title"
                  type="text"
                  onChange={(event) => setTitle({ value: event.target.value, error: '' })}
                />
                {
                  title.error && (
                    <label className="text-danger p-1">{title.error}</label>
                  )
                }
              </FormGroup>

              <FormGroup>
                <CustomerSearch onCustomerSelect={onSelectCustomer} onCustomer={true} />
              </FormGroup>

              {
                customerDetails !== undefined && customerDetails?.customer && (
                  <Card className="card-profile shadow mb-2">
                    <CardBody className="pt-0 pt-md-4">
                      <div className="text-center">
                        <h3>
                          {customerDetails?.customer?.name}
                          <span className="font-weight-light">, {customerDetails?.customer?.customer_id}</span>
                        </h3>
                        <div className="h5 font-weight-300">
                          <i className="ni location_pin mr-2" />
                          {customerDetails?.customer?.id}
                        </div>
                        <div className="h5 mt-4">
                          <i className="ni business_briefcase-24 mr-2" />
                          {customerDetails.customer?.contact_details}
                        </div>
                        <div>
                          <i className="ni education_hat mr-2" />
                          {customerDetails.customer?.phone_number}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                )
              }
              <Card className="card-profile shadow mb-2">
                <CardBody className="pt-0 pt-md-4">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Loan ID</th>
                        <th scope="col">Loan Amount</th>
                        <th scope="col">Re-payment Mode</th>
                        <th scope="col">Re-payment Amount</th>
                        <th scope="col">Paid Amount</th>
                        <th scope="col">Balance</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        customerDetails?.loans !== undefined && customerDetails?.loans.map((resource, index) => (
                          <tr key={index}>
                          <td>
                            {resource.title}
                          </td>
                          <td>
                            <span className="font-weight-bold">
                              {resource.loan_amount}
                            </span>
                          </td>
                          <td>
                            {resource.emi_mode}
                          </td>
                          <td>
                            {resource.monthly_emi}
                          </td>
                          <td>
                            <Button color="default" size="md" outline type="button">
                              {resource.total_amount_received} - {resource.no_of_emi_paid}/{resource.emi_mode_value}
                            </Button>
                          </td>
            
                          <td>
                            <span className="text-danger font-weight-bold h4">
                              {resource.loan_amount - resource.total_amount_received}
                            </span>
                            {
                              (resource.loan_amount - resource.total_amount_received) <= 0 && (
                                // resource.loan.loan_amount > 0 &&(
                                <>
                                  {/* <Button color="danger" size="sm" type="button" className="m-2" onClick={() => closeLoan(resource.loan.id)}>
                                    Close
                                  </Button> */}
                                </>
                              )
                            }
                          </td>
                          <td>
                            <a className="btn" href={`/admin/loans/detail/${resource.id}`}>View</a>
                          </td>
                        </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
              <FormGroup className={customerID.error ? 'has-danger' : ''}>
                <Select options={options} onChange={(selected) => {
                  setEmiMode({ value: selected.value, error: '' })
                }} />
              </FormGroup>

              {/* Loan Amount */}
              <FormGroup className={loanAmount.error ? 'has-danger' : ''}>
                <Input
                  className="form-control-alternative"
                  id="input-title"
                  placeholder="Loan Amount"
                  type="text"
                  onChange={(event) => setLoanAmount({ value: event.target.value, error: '' })}
                />
                {
                  loanAmount.error && (
                    <label className="text-danger p-1">{loanAmount.error}</label>
                  )
                }
              </FormGroup>


              {/* Loan Tenor */}
              <FormGroup className={tenor.error ? 'has-danger' : ''}>
                <Input
                  className="form-control-alternative"
                  id="input-title"
                  placeholder="EMI Tenor"
                  type="text"
                  onChange={(event) => setTenor({ value: event.target.value, error: '' })}
                />
                {
                  tenor.error && (
                    <label className="text-danger p-1">{tenor.error}</label>
                  )
                }
              </FormGroup>

              {/* EMI Mpnthly */}
              <FormGroup className={emiAmount.error ? 'has-danger' : ''}>
                <Input
                  className="form-control-alternative"
                  id="input-title"
                  placeholder="Monthly EMI"
                  type="text"
                  onChange={(event) => setEmiAmount({ value: event.target.value, error: '' })}
                />
                {
                  emiAmount.error && (
                    <label className="text-danger p-1">{emiAmount.error}</label>
                  )
                }
              </FormGroup>



              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime

                    inputProps={{
                      placeholder: "Date Picker Here"
                    }}
                    timeFormat={false}
                    onChange={(date) => setDate({ value: date, error: '' })}
                    closeOnClickOutside={true}
                    closeOnSelect={true}
                  />
                </InputGroup>
                {
                  date.error && (
                    <label className="text-danger p-1">{date.error}</label>
                  )
                }
              </FormGroup>


              <div className="text-center">
                <Button color="default" type="button" onClick={handleSubmit}>
                  Add
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
}

export {
  AddLoanModel
};
