import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  CardFooter,
} from "reactstrap";

import { useDispatch } from "react-redux";
import React, { useEffect, useState } from 'react';


import EmiActionTabs from "views/emi/tabs";
import PageTitle from "views/partials/pageTitle";
import { GetEmiUpcomingPayments } from "components/Appolo/emi.query";
import CustomerSearch from "views/partials/customerSearch";
import RenderPagination from "views/partials/pagination";
import EMITable from "./table";

function EmiUpcomingList() {
  const dispatch = useDispatch();
  
  const [emiList, setEmiList] = useState([]);
  const [customerID, setCustomerID] = useState()
  const [total, setTotal] = useState()

  const [meta, SetMeta] = useState([])
  const [pageLimit, setPageLimit] = useState(0)
  const variables = {
    page: 1,
    limit:pageLimit
  }
  const [runQuery, { loading: upLoading, error: upError, data: upData }] = GetEmiUpcomingPayments()

  // On filter clicked
  const handleFilter = () => {
    runQuery({ variables: { ...variables, customerID: customerID } })
  }


  // On clear clicked
  const handleClear = () => {
    runQuery({ variables: { ...variables } })
  }

  useEffect(() => {
    runQuery({ variables: { ...variables } })
  }, [dispatch]);

  useEffect(() => {
    SetMeta(upData?.upcomingEmiList?.meta_data)
    setEmiList(upData?.upcomingEmiList?.list)

    // set the total
    if (upData?.upcomingEmiList?.list !== null) {
      const sum = upData?.upcomingEmiList?.list.reduce((acc, obj) => acc + obj.loan.monthly_emi, 0);
      setTotal(sum)
    }

  }, [upData]);

  const handlePaginationClick = (page) => {
    runQuery({ variables: { ...variables, page: page } })
  }
  const handleLimitSelection = (count) => {
    runQuery({ variables: { ...variables,limit:count} })
    setPageLimit(count)
  }

  return (
    <>
      <PageTitle title="Upcoming list" />

      {/* Page content */}
      <Container className="mt--7" fluid>

        <EmiActionTabs />

        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Emi Upcoming List</h3>
                  </div>
                  <div className="col text-right">
                    <span className="h3 font-weight-bold mb-0 text-primary">
                      Total / Page: <i className="fa fa-inr" /> {total}</span>
                  </div>
                </Row>

                <Row className="mt-3">
                  <Col md="3">
                    <FormGroup>
                      <CustomerSearch onCustomerSelect={(selected) => {
                        setCustomerID(selected.value)
                      }} onCustomer={false} />
                    </FormGroup>
                  </Col>

                  <Col md="3">
                    <FormGroup>
                      <Button
                        className="btn-icon"
                        color="primary"
                        type="button"
                        size="md"
                        outline
                        onClick={handleFilter}
                      >Filter</Button>
                      <Button
                        className="btn-icon"
                        color="primary"
                        type="button"
                        size="md"
                        outline
                        onClick={handleClear}
                      >Clear</Button>
                    </FormGroup>
                  </Col>
                </Row>

              </CardHeader>
              <EMITable
                headers={[
                  "#", "Loan ID", "Title", "Customer ID", "Customer", "Loan Amount", "Re-payment Mode", "Re-payment Amount",
                  "Next Emi On", "Actions"
                ]}
                data={emiList}
              ></EMITable>
              <CardFooter>
                <div className="col text-right">
                  <RenderPagination 
                    meta={meta} 
                    handlePageClick={handlePaginationClick}
                    onLimitSelected={handleLimitSelection}
                  ></RenderPagination>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>


      </Container>
    </>
  );
};

export default EmiUpcomingList;
