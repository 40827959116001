import { useLazyQuery, useQuery } from '@apollo/client';

const { gql } = require("@apollo/client");
const CustomerCountGQL = gql`
    query customer_count {
        getCustomersCount
    }
`

const CustomersGQL = gql`
    query customers($page: Int!,$customerID: String) {
        customers(pagination:{
            page:$page
            limit:0
        }, filter:{
            customer_id: $customerID
        }) {
            list {
                id
                customer_id
                name
                contact_details
                phone_number
                notes
            }
            meta_data {
                currentPage
                currentElements
                totalPages
                totalElements
            }
        }
    }
`

const CustomerDetailsGQL = gql`
query get_customer_details($customer_id: String!) {
  customerDetails(customerID:$customer_id) {
    customer{
      id
      customer_id
      name
      phone_number
      contact_details
      notes
    }
    loans {
      id
      customer_id
      title
      emi_mode
      loan_amount 
      monthly_emi 
      loan_date 
      emi_mode_value 
      total_amount_received
      status
    }
    payments {
      id
      customer_id
      loan_id
      payment_date
      amount_paid
      status
    }
    files {
      id
      name
      type
      path
      status
    }
  }
}
`

const SearchCustomerGQL = gql`
  query search_customers($customerName: String) {
    searchCustomers(customerName:$customerName) {
      customer {
        id
        name
        customer_id
      }
    }
  }
`

// GetCustomerCount
const GetCustomerCount = () => {
  const { loading, error, data } = useQuery(CustomerCountGQL);
  return { loading, error, data };
}

// GetCustomers
const GetCustomers = () => {
  const [runQuery, { loading, error, data }] = useLazyQuery(CustomersGQL);
  return [runQuery, { loading, error, data }];
}
// GetCustomerDetails
const GetCustomerDetails = () => {
  const [runQuery, { loading, error, data }] = useLazyQuery(CustomerDetailsGQL);
  return [runQuery, { loading, error, data }];
}

// Search customer
const SearchCustomers = () => {
  const [runQuery, { loading, error, data }] = useLazyQuery(SearchCustomerGQL);
  return [runQuery, { loading, error, data }];
}


export {
  GetCustomerCount,
  GetCustomers,
  GetCustomerDetails,
  SearchCustomers
}