import { createSlice } from "@reduxjs/toolkit";
import {  toast } from 'react-toastify';

const initialState = {};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage: (state, action) => {
        Toaster(action.payload.type,action.payload.message)
      return { message: action.payload };
    },
    clearMessage: () => {
      return { message: "" };
    },
  },
});


const Toaster = (type, message) => {
    let fn = toast.info
    switch (type) {
        case 'error':
            fn = toast.error;
          break;
        case 'warn':
            fn = toast.warn;
          break;
        default:
          break;
    }
    fn(message,{
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
    });
}
const { reducer, actions } = messageSlice;

export const { setMessage, clearMessage } = actions
export default reducer;