import React from 'react';
import { formatDate } from "utils/helpers";
import moment from 'moment';

const LoanEmiDueDetails = ({ next_emi_date, currentDate }) => {
    const emiDate = next_emi_date !== undefined  ? moment(formatDate(next_emi_date), 'YYYY-MM-DD') : moment() ;
    const cdate = moment(currentDate, 'YYYY-MM-DD');
    const isDue = emiDate.isBefore(cdate);

  return (
    <div>
      
      <h4>Next Emi On : <a href="#" className="text-danger">
            {next_emi_date !== undefined && formatDate(next_emi_date)} 
        </a> 
        </h4>
        {/* {isDue && <span className="text-danger">Payment due</span>} */}
    </div>
  );
};

export default LoanEmiDueDetails;