// reactstrap components
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";
import { useDispatch,useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { nameValidator } from "helpers/nameValidator";
import { passwordValidator } from 'helpers/paswordValidator';
import { userLogin } from 'slices/api';

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const [username, setUsername] = useState({ value: '', error: '' })
  const [password, setPassword] = useState({ value: '', error: '' })

  const [isLoading, setIsLoading] = useState(false)

  const { isAuthenticated,apiError } = useSelector((state) => state.api);
console.log(apiError)
  const onLoginPressed = () => {
    const usernameError = nameValidator(username.value)
    const passwordError = passwordValidator(password.value)
    
    if (usernameError || passwordError) {
      setUsername({ ...username, error: usernameError })
      setPassword({ ...password, error: passwordError })
      return
    }
    setIsLoading(true)
    dispatch(userLogin({
      username:username.value, 
      password:password.value
    }))
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    },1000)
  }, [apiError,dispatch]);


  // redirect to admin page after logged in
  useEffect(() => {
    if (isAuthenticated === true) {
      navigate('/admin');
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <Col lg="6" md="6">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>sign in with credentials</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-circle-08" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Username"
                    type="text"
                    autoComplete="new-username"
                    onChange={(e) => setUsername({ value: e.target.value, error: '' })}
                    value={username.value}
                  />
                </InputGroup>
                {
                  username.error && (
                    <span className="text-danger">{username.error}</span>
                  )
                }
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    onChange={(e) => setPassword({ value: e.target.value, error: '' })}
                  />
                </InputGroup>
                {
                  password.error && (
                    <span className="text-danger">{password.error}</span>
                  )
                }
              </FormGroup>
             
              {/* <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div> */}
              {
                apiError && (
                  <p className="text-danger">{apiError}</p>
                )
              }
              {isLoading ? (
                // <ActivityIndicator />
                <div>Progressing....</div>
              ) : (
              <div className="text-center">
                <Button className="my-4" color="primary" type="button" onClick={onLoginPressed}>
                  Sign in
                </Button>
              </div>
              )}
            </Form>
          </CardBody>
        </Card>
        {/* <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Create new account</small>
            </a>
          </Col>
        </Row> */}
      </Col>
     
    </>
  );
};

export default Login;
