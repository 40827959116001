import apiService from "./api.service"
import authHeader from "./auth-header";

// get ttoal emi received
const fetchTotalSales = (filter) => {
    const params = apiService.BuildQueryParams(filter)
    const url = apiService.BuildURL("sales/total",params);

    return apiService.API.get(url.toString(),{
        headers: authHeader()
    });
};

const salesService = {
    fetchTotalSales
}
export default salesService
