
export function formatDate(dateString) {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString();
  return formattedDate;
}

export function IsEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}


// FormateDate
export function FormateDate(date) {
  const fdate = new Date(date);
  const formattedDate = fdate.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric"
  });
  return formattedDate
}