import apiService from "./api.service"
import authHeader from "./auth-header";



// addLoan
const addLoan = (data) => {
    return apiService.API.post("loan/",data,{
        headers: authHeader()
    })
}

// fetchCustomerLoans
const fetchCustomerLoans = (customerId, status) => {
    return apiService.API.get("loans/"+customerId + "?status="+(status ? status : ""),{
        headers: authHeader()
    })
}


// get investment list
const fetchLoanList = (filter) => {

    const params = apiService.BuildQueryParams(filter)
    const url = apiService.BuildURL("loans",params);
    return apiService.API.get(url,{
        headers: authHeader()
    });
};


// fetchCustomerLoans
const fetchActiveLoans = () => {
    return apiService.API.get("loans/active",{
        headers: authHeader()
    })
}

const loanService = {
    fetchCustomerLoans,
    fetchLoanList,
    fetchActiveLoans,
    addLoan,
}
export default loanService
