import apiService from "./api.service"
import authHeader from "./auth-header";


const fetchEmiList = (filter) => {
    const params = apiService.BuildQueryParams(filter)
    const url = apiService.BuildURL("emi/list",params);

    return apiService.API.get(url.toString(),{
        headers: authHeader()
    })
}


// get ttoal emi received
const fetchTotalEmi = (filter) => {
    const params = apiService.BuildQueryParams(filter)
    const url = apiService.BuildURL("emi/total",params);

    return apiService.API.get(url,{
        headers: authHeader()
    });
};

// addEmiPayment
const addEmiPayment = (payload) => {
    return apiService.API.post("emi",payload,{
        headers: authHeader()
    })
}


// Get customer emi payments
const fetchCustomerEmiPayments = (customerID) => {
    return apiService.API.get("emi/list/"+customerID,{
        headers: authHeader()
    })
}
// Get due emi list
const fetchEmiDueList = (filter) => {

    const params = apiService.BuildQueryParams(filter)
    const url = apiService.BuildURL("emi/due/list",params);
    return apiService.API.get(url,{
        headers: authHeader()
    });
}

// Get due emi count
const fetchEmiDueCount = () => {
    return apiService.API.get("emi/due/count",{
        headers: authHeader()
    })
}


const emiService = {
    fetchEmiList,
    fetchTotalEmi,
    fetchEmiDueList,
    addEmiPayment,
    fetchCustomerEmiPayments,
    fetchEmiDueCount,
}
export default emiService
