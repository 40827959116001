

import { GetStatusByCategory } from "components/Appolo/status.query";
import { useEffect, useState } from "react";
import {
  Badge,
  Button,
} from "reactstrap";


const statusColors = {
  "active": {
    "color": "success",
    "iconColor": "success"
  },
  "closed": {
    "color": "danger",
    "iconColor": "danger"
  }
}

const StatusText = ({ status, isButton }) => {
  const [statuses, setStatuses] = useState([])
  const [statusQuery, { loading: statusL, error: statusErr, data: statusD }] = GetStatusByCategory()
  useEffect(() => {
    statusQuery({
      variables: {
        "category": "COMMON",
      }
    })
  }, [statusQuery])


  useEffect(() => {
    const updatedStatus = statusD?.getStatusByCategory?.reduce((acc, value) => {
      return {
        ...acc,
        [value.id]: {
          id: value.id,
          display_value: value.display_value,
          display_name: value.display_name
        }
      };
    }, {});

    setStatuses(updatedStatus)
  }, [statusD])


  const stat = statuses?.[status]
  return (
    <>
      {isButton ? (
        <Button color={statusColors[stat?.display_value]?.color} outline>{stat?.display_name}</Button>
      ) : (
        <Badge color="" className="badge-dot mr-4">
          <i className={`bg-${statusColors[stat?.display_value]?.iconColor}`} />
          <span className={`text-${statusColors[stat?.display_value]?.color}`}>
            {stat?.display_name}
          </span>
        </Badge>
      )}
    </>
  )
}



export default StatusText