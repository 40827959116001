import { formatDate } from "utils/helpers"

const { Table, Button, Media } = require("reactstrap")

const EMITable = ({ headers, data }) => {
  return (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          {
            headers?.map((val, index) => (
              <th scope="col" key={index}>{val}</th>
            ))
          }
        </tr>
      </thead>
      <tbody>
        {
          data !== undefined && data?.map((resource, index) => (
            <tr key={index}>
              <td>{++index}</td>
              <td>
                <span className="font-weight-bold">
                  <Button color="default" size="md" outline type="button">
                    {resource.loan.id}
                  </Button>
                </span>
              </td>
              <td>
                {resource.loan.title}
              </td>
              <td>
                <Button color="default" size="md" type="button">
                  {resource.customer.customer_id}
                </Button>
              </td>
              <td>

                <Media className="align-items-center">
                  <a
                    className="avatar bg-white mr-3"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >

                    <img
                      alt="..."
                      src={require("../../assets/img/icons/user.png")}
                    />
                  </a>
                  <Media>
                    <span className="mb-0 text-sm">
                      {resource.customer.name}
                    </span>
                  </Media>
                </Media>
              </td>

              <td>
                <span className="font-weight-bold">
                  {resource.loan.loan_amount}
                </span>
              </td>
              <td>
                {resource.loan.emi_mode}
              </td>
              <td>

                <h1 className="h3 text-danger" color="danger"> {resource.loan.monthly_emi}</h1>
              </td>
              <td>
                <Button color="danger" size="md" outline type="button">
                  {formatDate(resource.due_date)}
                </Button>
              </td>
              <td>
                <a className="btn" href={`/admin/loans/detail/${resource.loan.id}`}>View</a>
              </td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  )
}

export default EMITable