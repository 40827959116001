import apiService from "./api.service"
import authHeader from "./auth-header";

// addNewCustomer
const addNewCustomer = (payload) => {
    return apiService.API.post("customer",payload,{
        headers: authHeader()
    })
}

// FetchCustomerDetails
const fetchCustomerDetails = (payload) => {
    return apiService.API.get("customer/" + payload,{
        headers: authHeader()
    })
}



// get all customer
const fetchCustomers = (filter) => {
    const params = apiService.BuildQueryParams(filter)
    const url = apiService.BuildURL("customers",params);
    return apiService.API.get(url.toString(),{
        headers: authHeader()
    });
};

const customerService = {
    addNewCustomer,
    fetchCustomerDetails,
    fetchCustomers
}
export default customerService
