const { gql, useQuery } = require("@apollo/client");


const ModeGQL = gql`
  query mode {
    mode
  }
`

// GetMode
const GetMode = () => {
    const { loading, error, data } = useQuery(ModeGQL);
    return { loading, error, data };
}

export {
  GetMode
}